export enum AppRoute {
  ROOT = '/',
  LOG_IN = '/log-in',
  USERS = '/users',
  CONTACTS = '/contacts',
  PARTNERS = '/partners',
  PROJECTS = '/projects',
  PROJECTS_ARCH = '/projects-arch',
  EVENTS = '/events',
  REPORTS = '/reports',
  DASHBOARD = '/dashboard',
  FORGOT_PASSWORD = '/forgot-password',
  FORGOT_PASSWORD_REQUEST = '/forgot-password-request',
  ANY = '*',
  STATISTICS = '/statistics',
  SEARCH = '/search',
  TASKS = '/tasks',
  SALES_NEW = '/sales-new',
  SALES_USED = '/sales-used',
  SERVICES = '/services'
}

export enum UsersRoute {
  ROLES = 'roles',
  ACCESS = 'access'
}

export enum DashboardRoute {
  PROJECTS = 'project',
  AUDIENCE = 'audience',
  CHILDREN = 'children',
  LOCATION = 'location',
  LOCATION_REG = 'location-reg',
  VOLUNTEER = 'volunteer',
  PARTNERS = 'partner'
}

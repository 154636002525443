import type { Dispatch, SetStateAction } from 'react';
import { Transition } from 'react-transition-group';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from '../transition';
import type { Project } from '../../../../models/project';
import { CloseEditSvg } from '../../../../components/svg/close-edit-svg';
import { UserInfo } from '../../../../components/user-info';
import { AddProject } from '../../add-project';
import {
  Close,
  EditHeader,
  EditHeaderRight,
  EditProjectData,
  EditProjectStyled,
  FormWrapper,
  ProjectId
} from './style';

interface EditProjectProps {
  setEdit: Dispatch<SetStateAction<boolean>>;
  project: Project;
  edit?: boolean;
}

export const EditProject = ({ setEdit, project, edit }: EditProjectProps) => (
  <Transition in={edit} mountOnEnter unmountOnExit timeout={duration}>
    {(state) => (
      <EditProjectStyled
        style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}
      >
        <div
          style={{
            ...defaultContentStyle,
            ...transitionContentStyles[state],
            height: '100%'
          }}
        >
          <EditProjectData>
            <Close onClick={() => setEdit(false)}>
              <CloseEditSvg />
            </Close>
            <EditHeader>
              <UserInfo
                isBig
                lastName={project.title}
                firstName=""
                patronymic=""
              />
              <EditHeaderRight>
                <ProjectId>ID Продажі: {project.id}</ProjectId>
              </EditHeaderRight>
            </EditHeader>
            <FormWrapper>
              <AddProject setShow={setEdit} project={project} />
            </FormWrapper>
          </EditProjectData>
        </div>
      </EditProjectStyled>
    )}
  </Transition>
);

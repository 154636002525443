import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Transition } from 'react-transition-group';
import { CardLayout } from '../../../layouts/card-layout';
import { getApiError } from '../../../helpers/get-api-error';
import { useGetUserMeQuery } from '../../../api/user-api';
import { NewNote } from '../../../components/new-note';
import { useNotification } from '../../../hooks/use-notification';
import {
  useEditProjectMutation,
  useGetCurrentProjectQuery
} from '../../../api/projects-api';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from './transition';
import { CurrentProjectSidebar } from './current-project-sidebar';
import { CurrentProjectTabs } from './current-project-tabs';
import { EditProject } from './edit-project';

interface CurrentProjectProps {
  id: string | number | null;
}

export const CurrentProject = ({ id }: CurrentProjectProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [newNote, setNewNote] = useState('');
  const [showNewNote, setShowNewNote] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, error } = useGetCurrentProjectQuery(
    { id },
    { refetchOnMountOrArgChange: true, skip: !id }
  );
  const [editProject] = useEditProjectMutation();

  const handleClose = useCallback(() => {
    setShowNewNote(false);
    setNewNote('');
    searchParams.delete('id');
    searchParams.delete('project_id');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleAddNote = () => {
    editProject({ id: id || '', body: { notes: [{ text: newNote }] } })
      .unwrap()
      .then(() => {
        setNewNote('');
        setShowNewNote(false);
        notification({
          type: 'success',
          title: 'Нотатку додано!',
          message: 'Ви успішно створили нотатку. '
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const canView = userMe?.role?.view_project || userMe?.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <Transition
      in={!!id && !isLoading && data && data.id.toString() === id.toString()}
      timeout={duration}
      unmountOnExit
      mountOnEnter
    >
      {(state) => (
        <CardLayout
          onClose={handleClose}
          sidebar={<CurrentProjectSidebar project={data} setEdit={setEdit} />}
          transitionStyle={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          transitionContentStyle={{
            ...defaultContentStyle,
            ...transitionContentStyles[state]
          }}
        >
          {error ? (
            getApiError(error)
          ) : (
            <CurrentProjectTabs
              projectId={id || ''}
              files={data?.files || []}
              setShowNewNote={setShowNewNote}
              notes={data?.notes || []}
              projectName={data?.title || ''}
            />
          )}
          {!!data && id && id === `${data.id}` && (
            <EditProject project={data} edit={edit} setEdit={setEdit} />
          )}
          <NewNote
            show={showNewNote}
            setShow={setShowNewNote}
            value={newNote}
            setValue={setNewNote}
            onSubmit={handleAddNote}
          />
        </CardLayout>
      )}
    </Transition>
  );
};

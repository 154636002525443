import type { FC } from 'react';
import { ActivitySvg } from '../../../../components/svg/activity-svg';
import { FileSvg } from '../../../../components/svg/file-svg';
import type { OptionItem } from '../../../../common/types/option-item';

export const tabList: { id: string; title: string; icon?: FC }[] = [
  { id: 'activity', title: 'Активність', icon: ActivitySvg },
  { id: 'files', title: 'Файли', icon: FileSvg }
];

export const activityTabs: OptionItem[] = [{ id: 'note', title: 'нотатки' }];

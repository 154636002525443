import type {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction
} from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchSvg } from '../svg/search-svg';
import { useDebounce } from '../../hooks/use-debounce';
import { useGetContactsQuery } from '../../api/contact-api';
import { LoaderStyled } from '../select/style';
import { CloseSvg } from '../svg/close-svg';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { setPhoneSearchTerm } from '../../api/page-data-slice';
import {
  IconClose,
  SearchIcon,
  SearchInput,
  SearchName,
  SearchStyled,
  SelectOptionItem,
  SelectOptions
} from './style';

interface SearchProps {
  placeholder?: string;
  showResults: boolean;
  setShowResults: Dispatch<SetStateAction<boolean>>;
  withOutGlobalSearch?: boolean;
}

export const Search = ({
  placeholder,
  showResults,
  setShowResults,
  withOutGlobalSearch
}: SearchProps) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [term, setTerm] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { phoneSearchTerm, isKanbanPage } = useTypedSelector(
    (state) => state['page-data']
  );

  const debounce = useDebounce(
    (term: string) => dispatch(setPhoneSearchTerm(term)),
    700
  );

  const handleClose = useCallback(() => {
    dispatch(setPhoneSearchTerm(''));
    setTerm('');
    setShowResults(false);
  }, [dispatch, setShowResults]);

  useEffect(() => {
    handleClose();
  }, [pathname, handleClose]);

  useOutsideClick(ref, () => setShowResults(false));
  const { pageSize } = useTypedSelector((state) => state['page-data']);
  const { data: contacts, isFetching } = useGetContactsQuery(
    {
      params: `?phone_search=${encodeURIComponent(
        phoneSearchTerm
      )}&size=${pageSize}`
    },
    { refetchOnMountOrArgChange: true, skip: !showResults }
  );

  const handleChangeTerm: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!isKanbanPage) {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
    setTerm(e.target.value);
    debounce(e.target.value);
    setShowResults(true);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
  };

  const handleShowContact = (id: string) => {
    searchParams.set('contact_id', id);
    dispatch(setPhoneSearchTerm(''));
    setTerm('');
    setSearchParams(searchParams);
  };

  return (
    <SearchStyled ref={ref}>
      <form onSubmit={handleSubmit}>
        <SearchInput
          value={term}
          onChange={handleChangeTerm}
          placeholder={placeholder}
        />
        <SearchIcon>
          <SearchSvg />
        </SearchIcon>

        {isFetching ? (
          <LoaderStyled right="12px"></LoaderStyled>
        ) : showResults || term ? (
          <IconClose onClick={handleClose}>
            <CloseSvg />
          </IconClose>
        ) : (
          ''
        )}
      </form>
      {!!contacts?.results.length && showResults && !withOutGlobalSearch && (
        <SelectOptions>
          {contacts.results.map((item) => (
            <SelectOptionItem
              onClick={() => handleShowContact(`${item.id}`)}
              key={item.id}
            >
              <span>{item.phone}</span>
              <SearchName>{item.title}</SearchName>
            </SelectOptionItem>
          ))}
        </SelectOptions>
      )}
    </SearchStyled>
  );
};

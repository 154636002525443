import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Transition } from 'react-transition-group';
import { CardLayout } from '../../../layouts/card-layout';
import { useGetUserMeQuery } from '../../../api/user-api';
import { NewNote } from '../../../components/new-note';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import {
  useEditTaskMutation,
  useGetCurrentTaskQuery
} from '../../../api/task-api';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from './transition';
import { CurrentTaskSidebar } from './current-task-sidebar';
import { CurrentTaskTabs } from './current-task-tabs';
import { EditTask } from './edit-task';

interface CurrentTaskProps {
  id: string | null;
}

export const CurrentTask = ({ id }: CurrentTaskProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [edit, setEdit] = useState(false);
  const [showNewNote, setShowNewNote] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useGetCurrentTaskQuery(
    { id },
    { refetchOnMountOrArgChange: true, skip: !id }
  );
  const [editTask] = useEditTaskMutation();

  const handleClose = useCallback(() => {
    searchParams.delete('task_id');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleAddNote = () => {
    editTask({ id: id || '', body: { notes: [{ text: newNote }] } })
      .unwrap()
      .then(() => {
        setNewNote('');
        setShowNewNote(false);
        notification({
          type: 'success',
          title: 'Нотатку додано!',
          message: 'Ви успішно створили нотатку. '
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  if (!userMe) {
    return null;
  }

  return (
    <Transition
      in={!!id && data && `${data.id}` === `${id}`}
      timeout={duration}
      unmountOnExit
      mountOnEnter
    >
      {(state) => (
        <CardLayout
          onClose={handleClose}
          sidebar={<CurrentTaskSidebar setEdit={setEdit} task={data} />}
          transitionStyle={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          transitionContentStyle={{
            ...defaultContentStyle,
            ...transitionContentStyles[state]
          }}
        >
          <CurrentTaskTabs
            notes={data?.notes || []}
            setShowNewNote={setShowNewNote}
            files={data?.files || []}
            contactId={data?.id || ''}
          />
          <NewNote
            show={showNewNote}
            setShow={setShowNewNote}
            value={newNote}
            setValue={setNewNote}
            onSubmit={handleAddNote}
          />
          {!!data && id && id === `${data.id}` && (
            <EditTask edit={edit} setEdit={setEdit} task={data} />
          )}
        </CardLayout>
      )}
    </Transition>
  );
};

import type {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction
} from 'react';
import { FormBtns } from '../../../components/form-btns';
import { Input } from '../../../components/input';
import { InputFile } from '../../../components/input-file';
import { Select } from '../../../components/select';
import type { CreateUser } from '../../../models/user';
import type { OptionItem } from '../../../common/types/option-item';
import { useGetUserRolesQuery } from '../../../api/user-api';
import { GENDER } from '../../../common/constants';
import type { ServerFile } from '../../../common/types/server-file';
import { FilesFromServer } from '../../../components/files-from-server';
import {
  AddUserStyled,
  InputsContainer,
  InputsGroupDouble,
  InputsGroupDoubleTitle,
  InputsGroupSingle,
  InputsGroupTitle
} from './style';

interface UserFormProps {
  fields: CreateUser;
  onSubmit: FormEventHandler<HTMLFormElement>;
  handleFieldsChange: ChangeEventHandler<HTMLInputElement>;
  role: OptionItem;
  setRole: Dispatch<SetStateAction<OptionItem>>;
  passportFiles: File[];
  setPassportFiles: Dispatch<SetStateAction<File[]>>;
  innFiles: File[];
  setInnFiles: Dispatch<SetStateAction<File[]>>;
  isLoading: boolean;
  isEdit?: boolean;
  onCancel: () => void;
  gender: OptionItem;
  setGender: Dispatch<SetStateAction<OptionItem>>;
  serverPassportFiles?: ServerFile[];
  setServerPassportFiles?: Dispatch<SetStateAction<ServerFile[]>>;
  serverInnFiles?: ServerFile[];
  setServerInnFiles?: Dispatch<SetStateAction<ServerFile[]>>;
}

export const UserForm = ({
  fields,
  onSubmit,
  handleFieldsChange,
  role,
  setRole,
  passportFiles,
  setPassportFiles,
  innFiles,
  setInnFiles,
  onCancel,
  isLoading,
  isEdit,
  gender,
  setGender,
  serverPassportFiles,
  setServerPassportFiles,
  serverInnFiles,
  setServerInnFiles
}: UserFormProps) => {
  const { data: roles } = useGetUserRolesQuery('');

  return (
    <AddUserStyled onSubmit={onSubmit}>
      <InputsContainer>
        {isEdit ? (
          <>
            <InputsGroupDoubleTitle fz="22px">
              Персональні дані
            </InputsGroupDoubleTitle>
            <InputsGroupSingle>
              <Input
                label="Прізвище"
                value={fields.last_name}
                onChange={handleFieldsChange}
                name="last_name"
              />
              <Input
                label="Ім'я*"
                value={fields.first_name}
                onChange={handleFieldsChange}
                name="first_name"
                required
              />
              <Input
                label="По Батькові"
                value={fields.patronymic}
                onChange={handleFieldsChange}
                name="patronymic"
              />
              <Select
                label="Гендер*"
                options={GENDER.slice(1)}
                value={gender}
                setValue={setGender}
              />
            </InputsGroupSingle>
            <InputsGroupSingle>
              <Input
                label="Телефон*"
                value={fields.phone}
                onChange={handleFieldsChange}
                type="tel"
                name="phone"
                mask="+38 999 999 99 99"
                required
              />
              <Input
                label="Email*"
                value={fields.email}
                onChange={handleFieldsChange}
                name="email"
                type="email"
                required
              />
            </InputsGroupSingle>
          </>
        ) : (
          <>
            <InputsGroupDouble>
              <InputsGroupDoubleTitle>Персональні дані</InputsGroupDoubleTitle>
              <Input
                label="Прізвище"
                value={fields.last_name}
                onChange={handleFieldsChange}
                name="last_name"
              />
              <Input
                label="Ім'я*"
                value={fields.first_name}
                onChange={handleFieldsChange}
                name="first_name"
                required
              />
              <Input
                label="По Батькові"
                value={fields.patronymic}
                onChange={handleFieldsChange}
                name="patronymic"
              />
              <Select
                label="Гендер*"
                options={GENDER.slice(1)}
                value={gender}
                setValue={setGender}
              />
              <Input
                label="Email*"
                value={fields.email}
                onChange={handleFieldsChange}
                name="email"
                type="email"
                required
              />
              <Input
                label="Телефон*"
                value={fields.phone}
                onChange={handleFieldsChange}
                type="tel"
                name="phone"
                mask="+38 999 999 99 99"
                required
              />
            </InputsGroupDouble>
            <InputsGroupSingle>
              <InputsGroupTitle>Дані CRM</InputsGroupTitle>
              <Select
                label="Роль CRM*"
                value={role}
                setValue={setRole}
                options={
                  roles?.results.map((item) => ({
                    id: item.id,
                    title: item.name
                  })) || []
                }
              />
              <Input
                label="Логін*"
                value={fields.username}
                onChange={handleFieldsChange}
                name="username"
                required
              />
              <Input
                label="Пароль*"
                value={fields.password}
                onChange={handleFieldsChange}
                name="password"
                required
              />
            </InputsGroupSingle>
          </>
        )}

        <InputsGroupDouble mt="20px">
          <InputsGroupDoubleTitle fz={isEdit ? '18px' : '16px'}>
            Адреса проживання
          </InputsGroupDoubleTitle>
          <Input
            label="Країна"
            value={fields.liv_country}
            onChange={handleFieldsChange}
            name="liv_country"
          />
          <Input
            label="Область"
            value={fields.liv_region}
            onChange={handleFieldsChange}
            name="liv_region"
          />
          <Input
            label="Місто / СМТ / Село"
            value={fields.liv_city}
            onChange={handleFieldsChange}
            name="liv_city"
          />
          <Input
            label="Район / ОТГ"
            value={fields.liv_district}
            onChange={handleFieldsChange}
            name="liv_district"
          />
          <Input
            label="Вулиця"
            value={fields.liv_street}
            onChange={handleFieldsChange}
            name="liv_street"
          />
          <Input
            label="Будинок"
            value={fields.liv_building}
            onChange={handleFieldsChange}
            name="liv_building"
          />
          <Input
            label="Корпус"
            value={fields.liv_unit}
            onChange={handleFieldsChange}
            name="liv_unit"
          />
          <Input
            label="Квартира"
            value={fields.liv_apartment}
            onChange={handleFieldsChange}
            name="liv_apartment"
          />
        </InputsGroupDouble>
        <InputsGroupDouble mt="20px">
          <InputsGroupDoubleTitle fz={isEdit ? '18px' : '16px'}>
            Адреса реєстрації
          </InputsGroupDoubleTitle>
          <Input
            label="Країна"
            value={fields.reg_country}
            onChange={handleFieldsChange}
            name="reg_country"
          />
          <Input
            label="Область"
            value={fields.reg_region}
            onChange={handleFieldsChange}
            name="reg_region"
          />
          <Input
            label="Місто / СМТ / Село"
            value={fields.reg_city}
            onChange={handleFieldsChange}
            name="reg_city"
          />
          <Input
            label="Район / ОТГ"
            value={fields.reg_district}
            onChange={handleFieldsChange}
            name="reg_district"
          />
          <Input
            label="Вулиця"
            value={fields.reg_street}
            onChange={handleFieldsChange}
            name="reg_street"
          />
          <Input
            label="Будинок"
            value={fields.reg_building}
            onChange={handleFieldsChange}
            name="reg_building"
          />
          <Input
            label="Корпус"
            value={fields.reg_unit}
            onChange={handleFieldsChange}
            name="reg_unit"
          />
          <Input
            label="Квартира"
            value={fields.reg_apartment}
            onChange={handleFieldsChange}
            name="reg_apartment"
          />
        </InputsGroupDouble>
        <div style={{ marginTop: '20px' }}>
          <InputsGroupTitle fz={isEdit ? '18px' : '16px'}>
            Документи
          </InputsGroupTitle>
          <Input
            mt="20px"
            label="Паспорт"
            value={fields.passport}
            onChange={handleFieldsChange}
            name="passport"
            placeholder="Ввведіть серію та номер паспорту"
          />
          {serverPassportFiles?.length && setServerPassportFiles ? (
            <FilesFromServer
              files={serverPassportFiles}
              setFiles={setServerPassportFiles}
            />
          ) : (
            ''
          )}
          <InputFile files={passportFiles} setFiles={setPassportFiles} />
        </div>
        <div style={{ marginTop: isEdit ? '23px' : '20px' }}>
          <Input
            mt="42px"
            label="ІПН"
            value={fields.id_number}
            onChange={handleFieldsChange}
            placeholder="Ввведіть номер ІПН"
            name="id_number"
            mask="9999999999"
            maskChar=" "
          />
          {serverInnFiles?.length && setServerInnFiles ? (
            <FilesFromServer
              files={serverInnFiles}
              setFiles={setServerInnFiles}
            />
          ) : (
            ''
          )}
          <InputFile files={innFiles} setFiles={setInnFiles} />
        </div>
        {isEdit ? (
          <>
            {' '}
            <InputsGroupDoubleTitle fz="22px">Дані CRM</InputsGroupDoubleTitle>
            <InputsGroupSingle>
              <Select
                label="Роль CRM*"
                value={role}
                setValue={setRole}
                options={
                  roles?.results.map((item) => ({
                    id: item.id,
                    title: item.name
                  })) || []
                }
              />
            </InputsGroupSingle>
            <InputsGroupSingle>
              <Input
                label="Логін*"
                value={fields.username}
                onChange={handleFieldsChange}
                name="username"
                required
              />
            </InputsGroupSingle>
          </>
        ) : (
          ''
        )}
      </InputsContainer>
      <FormBtns
        disabled={
          isLoading ||
          !fields.first_name ||
          !fields.email ||
          !fields.phone ||
          !role.id ||
          !gender.id ||
          !fields.username ||
          (!isEdit && !fields.password)
        }
        onCancel={onCancel}
      />
    </AddUserStyled>
  );
};

import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { TabItem } from '../../../../components/tab-item';
import type { ServerFile } from '../../../../common/types/server-file';
import { PageFiles } from '../../../../components/page-files';
import {
  useDeleteContactNoteMutation,
  useEditContactNoteMutation
} from '../../../../api/contact-api';
import { Activity } from '../../../../components/activity';
import type { Note } from '../../../../models/note';
import type { Project } from '../../../../models/project';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { useEditProjectMutation } from '../../../../api/projects-api';
import { TabContent, TabsStyled } from './style';
import { activityTabs, tabList } from './data';

interface CurrentProjectTabsProps {
  files?: ServerFile[] | null;
  projectId: string | number;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  notes: Note[];
  projectName: string;
}

export const CurrentProjectTabs = ({
  files,
  projectId,
  projectName,
  setShowNewNote,
  notes
}: CurrentProjectTabsProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [currentTab, setCurrentTab] = useState('activity');
  const [editProject, { isLoading }] = useEditProjectMutation();
  const [editNote] = useEditContactNoteMutation();
  const [deleteNote] = useDeleteContactNoteMutation();

  if (!userMe) {
    return null;
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'activity':
        return (
          <Activity
            notes={notes}
            tabs={activityTabs}
            setShowNewNote={setShowNewNote}
            calls={[]}
            setShowNewCall={() => {}}
            contactFullName={projectName}
            contactId={projectId}
            meets={[]}
            setShowNewMeet={() => {}}
            phone={''}
            editNote={editNote}
            deleteNote={deleteNote}
          />
        );
      case 'files':
        return (
          <PageFiles<Project>
            contactId={projectId}
            files={files || []}
            passportFiles={[]}
            innFiles={[]}
            editTrigger={editProject}
            isLoading={isLoading}
            dbFiles={[]}
            isDeleteLoading={isLoading}
            filesAddKey="files"
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <TabsStyled>
        {tabList.map((item, i) => (
          <TabItem
            onClick={setCurrentTab}
            $isActive={item.id === currentTab}
            {...item}
            key={i}
          />
        ))}
      </TabsStyled>
      <TabContent>{renderContent()}</TabContent>
    </>
  );
};

export const filterQueryParams = (
  params: string,
  paramToRemove: string | string[]
) => {
  const searchParams = new URLSearchParams(params);
  if (typeof paramToRemove === 'string') {
    searchParams.delete(paramToRemove);
  } else {
    paramToRemove.forEach((item) => {
      searchParams.delete(item);
    });
  }

  return searchParams.toString();
};

import { ApiPath } from '../common/enums/http/api-path.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { ReponseWithPagination } from '../common/types/response-with-pagination';
import type { Activity } from '../models/activity';
import type { Call } from '../models/call';
import type { Changes } from '../models/changes';
import type { Channel } from '../models/channel';
import type { Contact } from '../models/contact';
import type { VolunteersCities } from '../models/dashboard';
import type { Meet } from '../models/meet';
import { commonApi } from './common-api';
import { exportData } from './helpers';

const contactApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<
      ReponseWithPagination<Contact>,
      { params: string }
    >({
      query: ({ params }) => `${ApiPath.CONTACTS}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.CONTACTS]
    }),
    getContactsIdList: builder.query<number[], { params: string }>({
      query: ({ params }) =>
        `${ApiPath.CONTACTS_ID_LIST}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.CONTACTS_ID_LIST]
    }),
    getCurrentContact: builder.query<Contact, { id: number | string | null }>({
      query: ({ id }) =>
        `${ApiPath.CONTACTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.CONTACT]
    }),
    editContact: builder.mutation<
      Contact,
      { id: string | number; body: Record<string, unknown> | FormData }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.CONTACTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.CONTACTS, ApiTag.CONTACT, ApiTag.EVENT]
    }),
    createContact: builder.mutation<
      Contact,
      Record<string, unknown> | FormData
    >({
      query: (data) => ({
        url: `${ApiPath.CONTACTS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.CONTACTS]
    }),
    deleteContact: builder.mutation<null, { ids: (string | number)[] }>({
      query: ({ ids }) => ({
        url: `${ApiPath.CONTACTS}${ApiPath.ROOT}?ids=${ids.join(',')}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.CONTACTS]
    }),
    exportContacts: builder.mutation({
      query: (body: Record<string, string | number>) => ({
        url: ApiPath.CONTACTS_EXPORT,
        method: HttpMethod.POST,
        body,
        responseHandler: exportData,
        cache: 'no-cache'
      })
    }),
    importContact: builder.mutation<
      { message: string[]; downloads_errors: Record<string, string[]> },
      FormData
    >({
      query: (body) => ({
        url: ApiPath.CONTACTS_IMPORT,
        method: HttpMethod.POST,
        body
      }),
      invalidatesTags: [ApiTag.CONTACTS]
    }),
    deleteContactNote: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.CONTACT_NOTE_DETAILS}${ApiPath.ROOT}${id}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    editContactNote: builder.mutation<
      null,
      { id: string | number; body: { text: string } }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.CONTACT_NOTE_DETAILS}${ApiPath.ROOT}${id}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    createContactCall: builder.mutation<
      Call,
      Record<string, unknown> | FormData
    >({
      query: (data) => ({
        url: `${ApiPath.CONTACT_CALLS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    editContactCall: builder.mutation<
      null,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.CONTACT_CALLS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    deleteContactCall: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.CONTACT_CALLS}${ApiPath.ROOT}${id}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    createContactMeet: builder.mutation<
      Meet,
      Record<string, unknown> | FormData
    >({
      query: (data) => ({
        url: `${ApiPath.CONTACT_MEETS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    editContactMeet: builder.mutation<
      null,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.CONTACT_MEETS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    deleteContactMeet: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.CONTACT_MEETS}${ApiPath.ROOT}${id}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.CONTACT]
    }),
    getContactActivities: builder.query<
      Activity,
      { id: number | string | null }
    >({
      query: ({ id }) => `${ApiPath.CONTACT_ACTIVITY}${ApiPath.ROOT}${id}`,
      providesTags: [ApiTag.CONTACT]
    }),
    getContactChannels: builder.query<ReponseWithPagination<Channel>, ''>({
      query: () => `${ApiPath.CONTACT_CHANNELS}${ApiPath.ROOT}?size=100`,
      providesTags: [ApiTag.CONTACT]
    }),
    getContactChanges: builder.query<Changes, { id: number | string }>({
      query: ({ id }) => `${ApiPath.CONTACT_CHANGES}${ApiPath.ROOT}${id}`,
      providesTags: [ApiTag.CONTACT]
    }),
    getContactTags: builder.query<
      ReponseWithPagination<{ id: string | number; name: string }>,
      ''
    >({
      query: () => `${ApiPath.CONTACT_TAGS}/?size=100`
    }),
    getContactCategories: builder.query<
      ReponseWithPagination<{
        id: string | number;
        name: string;
        is_active: boolean;
      }>,
      ''
    >({
      query: () => `${ApiPath.CONTACT_CATEGORIES}?size=100`
    }),
    contactsDelegate: builder.mutation<
      Contact[],
      {
        delegate: string;
        new_owner: string | number;
        to_project: string | number;
        to_event: string | number;
      }
    >({
      query: (data) => ({
        url: `${ApiPath.CONTACTS_DELEGATE}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.CONTACTS]
    }),
    changeCommonStep: builder.mutation<
      Contact[],
      {
        delegate: string;
        event_step_id: string | number;
        event_id: string | number;
      }
    >({
      query: (data) => ({
        url: ApiPath.BULK_STEP_CHANGE,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.CONTACTS]
    }),
    changeSatisfaction: builder.mutation<
      Contact[],
      {
        contact_ids: string;
        satisfaction: string | number;
      }
    >({
      query: (data) => ({
        url: ApiPath.BULK_UPDATE_SATISFACTION,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.CONTACTS]
    }),
    getVolunteerCities: builder.query<VolunteersCities[], string>({
      query: () => ApiPath.VOLUNTEERS_CITIES
    })
  }),

  overrideExisting: false
});

export const {
  useGetContactsQuery,
  useCreateContactMutation,
  useGetCurrentContactQuery,
  useDeleteContactMutation,
  useEditContactMutation,
  useExportContactsMutation,
  useImportContactMutation,
  useDeleteContactNoteMutation,
  useEditContactNoteMutation,
  useCreateContactCallMutation,
  useEditContactCallMutation,
  useDeleteContactCallMutation,
  useGetContactActivitiesQuery,
  useCreateContactMeetMutation,
  useEditContactMeetMutation,
  useDeleteContactMeetMutation,
  useGetContactChannelsQuery,
  useGetContactChangesQuery,
  useContactsDelegateMutation,
  useGetContactTagsQuery,
  useGetContactCategoriesQuery,
  useGetContactsIdListQuery,
  useChangeCommonStepMutation,
  useChangeSatisfactionMutation,
  useGetVolunteerCitiesQuery
} = contactApi;

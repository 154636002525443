import type { Dispatch, SetStateAction } from 'react';
import { Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EditBtn } from '../../../../components/edit-btn';
import type { Contact } from '../../../../models/contact';
import { FlexContainer } from '../../../../components/flex-container';
import { useDeleteContactMutation } from '../../../../api/contact-api';
import { ActionBtn } from '../../../../components/action-btn';
import { ActionsEditSvg } from '../../../../components/svg/actions/actons-edit-svg';
import { ActionsCallSvg } from '../../../../components/svg/actions/actions-call-svg';
import { ActionsRemoveSvg } from '../../../../components/svg/actions/actions-remove-svg';
import { ActionsNoteSvg } from '../../../../components/svg/actions/actions-note-svg';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { ConfirmPopup } from '../../../../components/confirm-popup';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';
import type { OptionItem } from '../../../../common/types/option-item';
import { AccordionBlock } from '../../../../components/accordion-block';
import { AccordionItem } from '../../../../components/accordion-item';
import { IconContainer } from '../../../../components/icon-container';
import { ClipboardSvg } from '../../../../components/svg/clipboard-svg';
import {
  ContactId,
  ContactSidebarHeader,
  ContactSidebarStyled,
  FlexWrapper,
  FullName,
  HeaderItem
} from './style';
import { SidebarScrollBlock } from './sidebar-scroll-block';

interface CurrentConatctSidebarProps {
  contact?: Contact;
  setEdit: Dispatch<SetStateAction<boolean>>;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  setShowNewMeet: Dispatch<SetStateAction<boolean>>;
}

export const CurrentConatctSidebar = ({
  contact,
  setEdit,
  setShowNewNote,
  setShowNewCall,
  setShowNewMeet
}: CurrentConatctSidebarProps) => {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: userMe } = useGetUserMeQuery('');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteContact, { isLoading: deleteIsLoading }] =
    useDeleteContactMutation();

  const handleDelete = () => {
    deleteContact({ ids: [contact?.id || ''] })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Контакт видалено',
          message: 'Ви успішно видалили контакт'
        });
        searchParams.delete('id');
        searchParams.delete('contact_id');
        setSearchParams(searchParams);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleCall = (item: OptionItem) => {
    if (item.id === 'enter-call') {
      setShowNewCall(true);

      return;
    }
  };

  if (!userMe) {
    return null;
  }

  const canEdit = userMe?.role?.change_contact || userMe?.is_superuser;
  const canRemove = userMe.role?.delete_contact || userMe.is_superuser;

  return (
    <ContactSidebarStyled>
      {!!contact && (
        <>
          <ContactSidebarHeader>
            <HeaderItem>
              {canEdit && <EditBtn onClick={() => setEdit(true)} />}
            </HeaderItem>
            <FullName>{contact.title}</FullName>
            <HeaderItem mt="14px">
              <ContactId>ID Контакту: {contact.id}</ContactId>
            </HeaderItem>
            <HeaderItem mt="40px">
              <FlexContainer gap="10px" wrappMd>
                {canEdit && (
                  <ActionBtn
                    onClick={() => setShowNewNote(true)}
                    tooltip="Нотатка"
                  >
                    <ActionsEditSvg />
                  </ActionBtn>
                )}
                <ActionBtn
                  onClick={() => {}}
                  onOptionClick={handleCall}
                  options={[
                    {
                      id: 'call',
                      title: 'Зателефонувати',
                      href: `tel:${contact.phone}`
                    },
                    {
                      id: 'enter-call',
                      title: 'Внести дзвінок'
                    }
                  ]}
                  tooltip="Зателефонувати"
                >
                  <ActionsCallSvg />
                </ActionBtn>
                <ActionBtn
                  onClick={() => setShowNewMeet(true)}
                  tooltip="Внести зутріч"
                >
                  <ActionsNoteSvg />
                </ActionBtn>
              </FlexContainer>
              <FlexContainer gap="10px">
                {canRemove && (
                  <ActionBtn
                    onClick={() => setShowConfirmDelete(true)}
                    tooltip="Видалити"
                  >
                    <ActionsRemoveSvg />
                  </ActionBtn>
                )}
              </FlexContainer>
            </HeaderItem>
          </ContactSidebarHeader>
          <SidebarScrollBlock>
            <AccordionBlock title="Інформація про контакт">
              <AccordionItem title="Телефон" data={contact.phone} />
              <AccordionItem
                title="Email"
                data={
                  <FlexWrapper>
                    {contact.email}
                    {contact.email ? (
                      <IconContainer
                        m="0 0 0 8px"
                        onClick={() =>
                          navigator.clipboard.writeText(contact.email)
                        }
                      >
                        <ClipboardSvg />
                      </IconContainer>
                    ) : (
                      ''
                    )}
                  </FlexWrapper>
                }
              />
              <AccordionItem title="Місто" data={contact.city} />
              <AccordionItem title="Адрес" data={contact.address} />
              <AccordionItem title="Коментар" data={contact.comment} />
            </AccordionBlock>
            <AccordionBlock title="Контактні особи">
              {contact.contact_persons.map((item) => (
                <Fragment key={item.id}>
                  <AccordionItem title="Імʼя" data={item.full_name} />
                  <AccordionItem title="Телефон" data={item.phone} />
                </Fragment>
              ))}
            </AccordionBlock>
          </SidebarScrollBlock>
        </>
      )}
      <ConfirmPopup
        show={showConfirmDelete}
        setShow={setShowConfirmDelete}
        title={`Чи дійсно бажаєте видалити контакт ${contact?.title}`}
        onSubmit={handleDelete}
        disabled={deleteIsLoading}
        needTranslate
      />
    </ContactSidebarStyled>
  );
};

import type { FC } from 'react';
import { AppRoute } from '../../common/enums/app/app-route.enum';
import { ApiTag } from '../../common/enums/http/api-tag.enum';
import { ContactsSvg } from '../svg/sidebar/contacts-svg';
import { UsersSvg } from '../svg/sidebar/users-svg';
import { PartnerSvg } from '../svg/sidebar/partner-svg';
import { TaskSvg } from '../svg/sidebar/task-svg';
import { ProjectsSvg } from '../svg/sidebar/projects-svg';

interface Menu {
  title: string;
  icon: FC;
  path?: string;
  tag?: ApiTag;
  iconStrokeColored?: boolean;
  list?: {
    title: string;
    icon: FC;
    path: AppRoute;
    tag: ApiTag;
    iconStrokeColored?: boolean;
  }[];
}

export const managerMenuList: Menu[] = [
  {
    title: 'Контакти',
    icon: ContactsSvg,
    path: AppRoute.CONTACTS,
    tag: ApiTag.CONTACTS
  },
  {
    title: 'Завдання',
    icon: TaskSvg,
    path: `${AppRoute.TASKS}?kanban=true`
  },
  {
    title: 'Продажі нові',
    icon: ProjectsSvg,
    path: AppRoute.PROJECTS
  },
  {
    title: 'Продажі б/у',
    icon: ProjectsSvg,
    path: AppRoute.SALES_USED
  },
  {
    title: 'Сервіс',
    icon: ProjectsSvg,
    path: AppRoute.SERVICES
  }
];

export const menuList: Menu[] = [
  {
    title: 'Співробітники',
    icon: UsersSvg,
    path: AppRoute.USERS,
    tag: ApiTag.USERS
  },
  {
    title: 'Партнери',
    icon: PartnerSvg,
    path: AppRoute.PARTNERS,
    tag: ApiTag.CONTACTS
  },
  {
    title: 'Контакти',
    icon: ContactsSvg,
    path: AppRoute.CONTACTS,
    tag: ApiTag.CONTACTS
  },
  {
    title: 'Завдання',
    icon: TaskSvg,
    path: `${AppRoute.TASKS}?kanban=true`
  },
  {
    title: 'Продажі нові',
    icon: ProjectsSvg,
    path: AppRoute.PROJECTS,
    tag: ApiTag.PROJECTS
  },
  {
    title: 'Продажі б/у',
    icon: ProjectsSvg,
    path: AppRoute.SALES_USED,
    tag: ApiTag.PROJECTS
  },
  {
    title: 'Сервіс',
    icon: ProjectsSvg,
    path: AppRoute.SERVICES,
    tag: ApiTag.PROJECTS
  }
];

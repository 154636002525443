import type { Dispatch, SetStateAction } from 'react';
import { BtnPrimary } from '../btn-primary';
import { splitByMonth } from '../../helpers/split-by-month';
import type { Call as ICall } from '../../models/call';
import { BtnSecondary } from '../btn-secondary';
import type {
  DeleteCall,
  EditCall
} from '../../common/types/rtk-triggers/call';
import {
  CreateCall,
  CallsByMonth,
  CallsByMonthTitle,
  CallsContainer,
  CallsStyled
} from './style';
import { Call } from './call';

interface CallsProps {
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  calls: ICall[];
  contactFullName: string;
  phone?: string;
  editCall: EditCall;
  deleteCall: DeleteCall;
  isPartner?: boolean;
}

export const Calls = ({
  setShowNewCall,
  calls,
  contactFullName,
  phone,
  editCall,
  deleteCall,
  isPartner
}: CallsProps) => (
  <CallsStyled>
    <CreateCall>
      <BtnSecondary onClick={() => setShowNewCall(true)}>
        Внести дзвінок
      </BtnSecondary>
      <a href={`tel:${phone}`}>
        {' '}
        <BtnPrimary onClick={() => {}}>Зателефонувати</BtnPrimary>
      </a>
    </CreateCall>
    <CallsContainer>
      {splitByMonth<ICall>(calls).map((item, i) => (
        <CallsByMonth key={i}>
          <CallsByMonthTitle>{item.title}</CallsByMonthTitle>
          {item.list.map((call) => (
            <Call
              key={call.id}
              call={call}
              fullName={contactFullName}
              editCall={editCall}
              deleteCall={deleteCall}
              isPartner={isPartner}
            />
          ))}
        </CallsByMonth>
      ))}
    </CallsContainer>
  </CallsStyled>
);

import { Navigate } from 'react-router-dom';
import type { FC } from 'react';
import { useTypedSelector } from '../../api/store';
import { useCheckAuth } from '../../hooks/use-check-auth';
import { MainLayout } from '../../layouts/main-layout';
import { AppRoute } from '../../common/enums/app/app-route.enum';

interface PrivateRouteProps {
  component: FC;
  customHeader?: boolean;
}

const PrivateRoute = ({
  component: Component,
  customHeader,
  ...rest
}: PrivateRouteProps & Record<string, unknown>) => {
  const { isAuthorized } = useTypedSelector((state) => state.auth);
  useCheckAuth();

  if (isAuthorized === 'needToCheck') return null;

  return isAuthorized === 'yes' ? (
    <MainLayout customHeader={customHeader}>
      <Component {...rest} />
    </MainLayout>
  ) : (
    <Navigate to={{ pathname: AppRoute.LOG_IN }} />
  );
};

export { PrivateRoute };

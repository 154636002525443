import styled from 'styled-components';
import { scrollBar } from '../../../style/helpers';

export const KanbanStyled = styled.div<{ colCount: number }>`
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  gap: 10px;
  width: 100%;
  ${scrollBar}
`;

import type { ReactNode } from 'react';
import { useState } from 'react';
import { Tooltip } from '../tooltip';
import type { OptionItem } from '../../common/types/option-item';
import {
  ActionBtnStyled,
  Options,
  AcyionOptionItem,
  Wrapper,
  OptionsWrapper
} from './style';

interface ActionBtnProps {
  children: ReactNode;
  onClick: () => void;
  tooltip: string;
  options?: (OptionItem & { href?: string })[];
  onOptionClick?: (item: OptionItem) => void;
}

export const ActionBtn = ({
  children,
  onClick,
  tooltip,
  options,
  onOptionClick
}: ActionBtnProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Wrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <ActionBtnStyled onClick={onClick}>
        {children}
        <Tooltip show={isHover && !options}>{tooltip}</Tooltip>
      </ActionBtnStyled>
      {isHover && options?.length && (
        <OptionsWrapper>
          <Options>
            {options.map((item) =>
              item.href ? (
                <AcyionOptionItem as="a" href={item.href} key={item.id}>
                  {item.title}
                </AcyionOptionItem>
              ) : (
                <AcyionOptionItem
                  key={item.id}
                  onClick={() => (onOptionClick ? onOptionClick(item) : '')}
                >
                  {item.title}
                </AcyionOptionItem>
              )
            )}
          </Options>
        </OptionsWrapper>
      )}
    </Wrapper>
  );
};

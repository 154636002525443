import type { Filter } from '../../common/types/filters';
import type { HeaderItem } from '../../components/data-table';
import type { ContactTable } from './types';

export const tableHeaders: HeaderItem<ContactTable>[] = [
  { id: 'id', title: 'ID', checked: true },
  { id: 'title', title: 'Назва', checked: true },
  { id: 'phone', title: 'Телефон', checked: true },
  { id: 'contact_persons', title: 'Контактна особа', checked: true },
  { id: 'email', title: 'Email', checked: true },
  { id: 'sale', title: 'Тип продажі', checked: true },
  { id: 'is_partner', title: 'Партнер', checked: true },
  { id: 'city', title: 'Місто', checked: true },
  { id: 'address', title: 'Адреса', checked: true },
  { id: 'comment', title: 'Коментар', checked: true }
];

export const initialFilters: Filter[] = [
  {
    type: 'checkbox',
    isSingly: true,
    checkList: [
      {
        id: 'is_partner',
        checked: false,
        title: 'Партнер'
      }
    ],
    title: 'Вид контакту',
    name: 'type'
  },
  {
    type: 'checkbox',
    checkList: [
      { id: 1, title: 'Пряма продажа', checked: false },
      { id: 2, title: 'Посердник', checked: false }
    ],
    title: 'Тип продажі',
    name: 'sale'
  }
];

import type { UIEventHandler } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoaderSmall } from '../../../loader-small';
import { useGetTaskNotifsQuery } from '../../../../api/task-api';
import { dateTableFormat } from '../../../../helpers/date-helpers';
import { NotifContent, NotifContentHeader, NotifTitle, Text } from './style';

export const HeaderNotifContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [size, setSize] = useState(25);

  const { data, isFetching } = useGetTaskNotifsQuery(
    { params: `?size=${size}` },
    { refetchOnMountOrArgChange: true }
  );

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const elem = e.target as HTMLElement;
    const scrollHeight = elem.scrollHeight;
    if (
      !!data &&
      Math.ceil(elem.scrollTop + elem.offsetHeight) >= scrollHeight &&
      !isFetching &&
      data.results.length < data.count
    ) {
      setSize(data.results.length + 25);
    }
  };

  const handleShowTask = (id?: string | number) => {
    if (id) {
      searchParams.set('task_id', `${id}`);
      setSearchParams(searchParams);
    }
  };

  return (
    <NotifContent onScroll={handleScroll}>
      {data?.count
        ? data?.results.map((item) => (
            <div
              key={item.task_id ? `${item.id}_task` : `${item.id}_project`}
              onClick={() => handleShowTask(item.task_id)}
            >
              <NotifTitle>
                <div>
                  <div>{item.message.split(':')[0]}:</div>
                  <Text>{item.message.split(':').slice(1).join(':')}</Text>
                </div>
                <span>{dateTableFormat(item.created_at)}</span>
              </NotifTitle>
              {/* <Text>{item.message}</Text> */}
            </div>
          ))
        : !isFetching && (
            <NotifContentHeader>Немає нових сповіщень</NotifContentHeader>
          )}
      {isFetching && <LoaderSmall mt="10px" ac />}
      {/* <LoaderSmall mt="10px" ac /> */}
    </NotifContent>
  );
};

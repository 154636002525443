import type { FormEventHandler } from 'react';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { FormBtns } from '../../../components/form-btns';
import { useChangeFields } from '../../../hooks/use-change-fields';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import {
  useCreateProjectMutation,
  useEditProjectMutation,
  useGetCurrenciesQuery,
  useGetProjectsStepsQuery
} from '../../../api/projects-api';
import type { OptionItem } from '../../../common/types/option-item';
import { mapUsersToProps } from '../../../helpers/map-users-to-option';
import { useGetUsersQuery } from '../../../api/user-api';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { useGetContactsQuery } from '../../../api/contact-api';
import { TextArea } from '../../../components/text-area';
import { InputDate } from '../../../components/input-date';
import {
  dateToApiFromInput,
  dateToUIFormat,
  isValidNewDate
} from '../../../helpers/date-helpers';
import type { Project } from '../../../models/project';
import {
  AddProjectStyled,
  BudgetWrapper,
  DoubleRow,
  GroupTitle,
  InputsContainer,
  ManagerItem
} from './style';

interface AddProjectProps {
  setShow: Dispatch<SetStateAction<boolean>>;
  project?: Project;
  saleType?: string;
}

interface TextFields {
  title: string;
  description: string;
  budget: string;
}

export const AddProject = ({ setShow, project, saleType }: AddProjectProps) => {
  const notification = useNotification();
  const [responsible, setResponsible] = useState<OptionItem[]>([
    {
      id: '',
      title: ''
    }
  ]);
  const [termResponsible, setTermResponsible] = useState('');
  const [debounceResponsibleTerm, setDebounceResponsibleTerm] = useState('');
  const [contact, setContact] = useState<OptionItem>({ id: '', title: '' });
  const [deadlines, setDeadLines] = useState<Record<string, string>>({});
  const [currency, setCurrency] = useState<OptionItem>({ id: '', title: '' });

  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<TextFields>({
      title: '',
      description: '',
      budget: ''
    });

  const { data: users, isFetching: isFetchingUsers } = useGetUsersQuery(
    { params: `?size=100&search=${debounceResponsibleTerm}` },
    { refetchOnMountOrArgChange: true }
  );
  const { data: contacts } = useGetContactsQuery({ params: '?size=100' });
  const { data: steps } = useGetProjectsStepsQuery({ params: '' });
  const { data: currencies } = useGetCurrenciesQuery({ params: '' });

  const [createProject, { isLoading }] = useCreateProjectMutation();
  const [editProject, { isLoading: isLoadingEdit }] = useEditProjectMutation();

  useEffect(() => {
    if (project) {
      updateFields({
        title: project.title,
        description: project.description,
        budget: project.budget
      });
      setContact(project.contact);
      setCurrency(project.currency || { id: '', title: '' });
      setResponsible(
        project.resp_managers.length
          ? project.resp_managers.map((item) => ({
              id: item.id,
              title: item.full_name
            }))
          : [
              {
                id: '',
                title: ''
              }
            ]
      );
      setDeadLines(
        Object.fromEntries(
          project.step_deadlines.map((item) => [
            item.step.id,
            dateToUIFormat(new Date(item.date))
          ])
        )
      );
    }
  }, [project, updateFields]);

  const notAllFieldsAreFilled = !fields.title;

  const handleDeleteManager = (i: number) => {
    if (responsible && responsible.length === 1) {
      setResponsible([{ id: '', title: '' }]);
    } else {
      setResponsible((state) => state.filter((_, j) => j !== i));
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const enteredData = Object.entries(fields).filter((item) => item[1]);
    const stepsDeadlines = Object.entries(deadlines)
      .filter((item) => isValidNewDate(dateToApiFromInput(item[1])))
      .map(([step_id, date]) => ({ step_id, date: dateToApiFromInput(date) }));

    if (project) {
      editProject({
        id: project.id,
        body: {
          ...Object.fromEntries(enteredData),
          steps_deadlines: stepsDeadlines,
          contact: contact.id,
          resp_managers: responsible.map((item) => item.id),
          currency: currency.id || undefined
        }
      })
        .unwrap()
        .then(() => {
          notification({
            type: 'success',
            message: 'Продажу змінено',
            title: 'Ви успішно змінили продажу'
          });
          setShow(false);
        })
        .catch((err) => {
          notification({
            type: 'error',
            message: 'Помилка',
            title: getApiError(err)
          });
        });

      return;
    }
    createProject({
      ...Object.fromEntries(enteredData),
      steps_deadlines: stepsDeadlines,
      contact: contact.id,
      sale_type: saleType,
      current_step: steps?.results && steps.results[0].id,
      resp_managers: responsible.map((item) => item.id),
      currency: currency.id || undefined
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          message: 'Продажу додано',
          title: 'Ви успішно створили продажу'
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          message: 'Помилка',
          title: getApiError(err)
        });
      });
  };

  return (
    <AddProjectStyled onSubmit={handleSubmit}>
      <InputsContainer>
        <Input
          label="Назва*"
          value={fields.title}
          name="title"
          onChange={handleFieldsChange}
          required
        />
        {responsible.map((item, i) => (
          <ManagerItem key={i}>
            <Select
              label="Відповідальний*"
              value={item}
              setValue={(value) =>
                setResponsible((state) =>
                  state.map((manager, j) => (i === j ? value : manager))
                )
              }
              options={mapUsersToProps(users?.results)}
              isLoading={isFetchingUsers}
              isSearch
              term={termResponsible}
              setTerm={setTermResponsible}
              setDebounceTerm={setDebounceResponsibleTerm}
            />
            {responsible.length === 1 && !responsible[0].id ? (
              ''
            ) : (
              <IconContainer
                m="0 0 11px 0"
                onClick={() => handleDeleteManager(i)}
              >
                <CloseEditSvg />
              </IconContainer>
            )}
          </ManagerItem>
        ))}
        <Select
          label="Контакт*"
          options={
            contacts?.results.map((item) => ({
              id: item.id,
              title: item.title
            })) || []
          }
          value={contact || { id: '', title: '' }}
          setValue={setContact}
        />
        <DoubleRow>
          <TextArea
            label="Опис"
            value={fields.description}
            name="description"
            onChange={handleFieldsChange}
          />
        </DoubleRow>
        <BudgetWrapper>
          <Input
            label="Сума"
            value={fields.budget}
            name="budget"
            onChange={handleFieldsChange}
            type="number"
          />
          <Select
            label="Валюта"
            options={currencies?.results}
            value={currency}
            setValue={setCurrency}
            placeholder=""
          />
        </BudgetWrapper>

        <GroupTitle>Дедлайни по етапах</GroupTitle>
        <div></div>
        {steps?.results.map((item, i) => (
          <InputDate
            key={item.id}
            label={`${item.title} (${i + 1})`}
            value={deadlines[item.id] || ''}
            setValue={(date) =>
              setDeadLines((state) => ({ ...state, [item.id]: date }))
            }
          />
        ))}
      </InputsContainer>
      <FormBtns
        disabled={
          notAllFieldsAreFilled ||
          isLoading ||
          isLoadingEdit ||
          !responsible.filter((item) => item.id).length ||
          !contact.id
        }
        onCancel={() => setShow(false)}
      />
    </AddProjectStyled>
  );
};

import type { HeaderItem } from '../../components/data-table';
import type { Filter } from '../users/types';
import type { ProjectTable } from './types';

export const tableHeaders: HeaderItem<ProjectTable>[] = [
  { id: 'id', title: 'ID' },
  { id: 'title', title: 'Назва' },
  { id: 'budget', title: 'Сума' },
  { id: 'currency', title: 'Валюта' },
  { id: 'contact', title: 'Контакт' },
  { id: 'responsible', title: 'Відповідальний' },
  { id: 'description', title: 'Опис' },
  { id: 'current_step', title: 'Етап' },
  { id: 'current_deadline', title: 'Дедлайн етапу' }
];

export const initialFilters: Filter[] = [
  {
    type: 'checkbox',
    checkList: [],
    title: 'Відповідальний',
    name: 'resp_managers'
  }
  // {
  //   type: 'checkbox',
  //   checkList: [],
  //   title: 'Етап',
  //   name: 'current_step'
  // }
];

export const initialKanbanFilters: Filter[] = [
  {
    type: 'checkbox',
    checkList: [],
    title: 'Відповідальний',
    name: 'resp_managers'
  }
];

import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const EditProjectStyled = styled.div`
  padding-left: 53%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: calc(100vw * -0.17);
  top: 0;
  background: ${(p) => p.theme.colors.popup.bg};
  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    padding-left: 45%;
    left: calc(100vw * -0.1);
  }
`;

export const EditProjectData = styled.div`
  padding-top: 20px;
  position: relative;
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  height: 100%;
  box-shadow: -4px 4px 21px rgba(0, 0, 0, 0.12);
`;

export const Close = styled.div`
  position: absolute;
  left: -32px;
  top: 80px;
  width: 58px;
  height: 58px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.card.bg};
  padding: 19px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: -1;
`;

export const EditHeader = styled.div`
  padding-bottom: 20px;
  width: calc(100% - 80px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
`;

export const EditHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProjectId = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const FormWrapper = styled.div`
  padding: 0 40px;
  height: calc(100vh - 120px);
  ${scrollBar}
`;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { HeaderItem } from '../components/data-table';
import type { ContactTable } from '../pages/contacts/types';

type PageDataState = {
  contactColumns: HeaderItem<ContactTable>[] | null;
  pageSize: number;
  menuIsOpen: boolean;
  phoneSearchTerm: string;
  isKanbanPage: boolean;
};

const initialState: PageDataState = {
  contactColumns: null,
  pageSize: 25,
  menuIsOpen: true,
  phoneSearchTerm: '',
  isKanbanPage: false
};

const pageDataSlice = createSlice({
  name: 'page-data',
  initialState,
  reducers: {
    setContactColumns: (
      state,
      { payload }: PayloadAction<HeaderItem<ContactTable>[] | null>
    ) => {
      state.contactColumns = payload;
    },
    deleteContactColumns: (state) => {
      state.contactColumns = null;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    toggleMenu: (state) => {
      state.menuIsOpen = !state.menuIsOpen;
    },
    closeMenu: (state) => {
      state.menuIsOpen = false;
    },
    setPhoneSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.phoneSearchTerm = payload;
    },
    setIsKanbanPage: (state, { payload }: PayloadAction<boolean>) => {
      state.isKanbanPage = payload;
    }
  }
});

export const {
  setContactColumns,
  setPageSize,
  toggleMenu,
  closeMenu,
  setPhoneSearchTerm,
  setIsKanbanPage
} = pageDataSlice.actions;

export default pageDataSlice.reducer;

import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import { CloseEditSvg } from '../../../../components/svg/close-edit-svg';
import { useChangeFields } from '../../../../hooks/use-change-fields';
import { getApiError } from '../../../../helpers/get-api-error';
import { useNotification } from '../../../../hooks/use-notification';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from '../transition';
import type { Contact } from '../../../../models/contact';
import { useEditContactMutation } from '../../../../api/contact-api';
import type { ContactTextFields } from '../../contact-form';
import { ContactForm } from '../../contact-form';
import { useGetUserMeQuery } from '../../../../api/user-api';
import type { OptionItem } from '../../../../common/types/option-item';
import {
  Close,
  ContactId,
  EditContactData,
  EditContactStyled,
  EditHeader,
  FormWrapper,
  HeaderRow,
  Title
} from './style';

interface EditContactProps {
  setEdit: Dispatch<SetStateAction<boolean>>;
  contact: Contact;
  edit?: boolean;
}

export const EditContact = ({ setEdit, contact, edit }: EditContactProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const notification = useNotification();

  const [editContact, { isLoading }] = useEditContactMutation();
  const [saleType, setSaleType] = useState<OptionItem>({ id: '', title: '' });
  const [isPartner, setIsPartner] = useState(false);
  const [contactPersons, setContactPersons] = useState([
    { full_name: '', phone: '' }
  ]);
  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<ContactTextFields>({
      title: '',
      phone: '',
      email: '',
      comment: '',
      city: '',
      address: ''
    });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const fieldsHasBeenChanged = Object.entries(fields).filter(
      ([key, value]) => contact[key as keyof Contact] !== value
    );
    const formData = new FormData();
    fieldsHasBeenChanged.forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (saleType) {
      formData.append('sale', `${saleType.id}`);
    }
    formData.append('is_partner', `${isPartner}`);
    formData.append(
      'persons',
      JSON.stringify(contactPersons.filter((item) => item.full_name))
    );

    editContact({ id: contact.id, body: formData })
      .unwrap()
      .then(() => {
        setEdit(false);
        notification({
          type: 'success',
          title: 'Контакт відредаговано!',
          message: 'Ви успішно відредагували контакт.'
        });
      })
      .catch((err) => {
        console.log(err);
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  useEffect(() => {
    updateFields({
      title: contact.title,
      phone: contact.phone,
      email: contact.email,
      comment: contact.comment,
      city: contact.city,
      address: contact.address
    });
    setContactPersons(
      contact.contact_persons.map((item) => ({
        id: item.id,
        full_name: item.full_name,
        phone: item.phone
      }))
    );
    setSaleType(contact.sale);
    setIsPartner(contact.is_partner);
  }, [contact, updateFields, edit]);

  if (!userMe) {
    return null;
  }

  return (
    <Transition
      in={edit && !!contact}
      mountOnEnter
      unmountOnExit
      timeout={duration}
    >
      {(state) => (
        <EditContactStyled
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <div
            style={{
              ...defaultContentStyle,
              ...transitionContentStyles[state],
              height: '100%'
            }}
          >
            <EditContactData>
              <Close onClick={() => setEdit(false)}>
                <CloseEditSvg />
              </Close>
              <EditHeader>
                <HeaderRow>
                  <Title>{contact.title}</Title>
                  <ContactId>ID Контакту: {contact.id}</ContactId>
                </HeaderRow>
              </EditHeader>
              <FormWrapper>
                <ContactForm
                  fields={fields}
                  saleType={saleType}
                  setSaleType={setSaleType}
                  handleFieldsChange={handleFieldsChange}
                  isLoading={isLoading}
                  setShow={setEdit}
                  onSubmit={handleSubmit}
                  contactPersons={contactPersons}
                  setContactPersons={setContactPersons}
                  isPartner={isPartner}
                  setIsPartner={setIsPartner}
                />
              </FormWrapper>
            </EditContactData>
          </div>
        </EditContactStyled>
      )}
    </Transition>
  );
};

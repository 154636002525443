import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useGetProjectsQuery,
  useGetProjectsStepsQuery
} from '../../api/projects-api';
import { ButtonAdd } from '../../components/button-add';
import { Counter } from '../../components/counter';
import { FlexContainer } from '../../components/flex-container';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import { Popup } from '../../components/popup';
import { SwitcherKanban } from '../../components/switcher-kanban';
import { NoData } from '../../components/no-data';
import { DataTable } from '../../components/data-table';
import { useTypedSelector } from '../../api/store';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { StringCutter } from '../../components/string-cutter';
import {
  dateTableFormat,
  dateToApiFormat,
  isValidNewDate
} from '../../helpers/date-helpers';
import { useGetUserMeQuery } from '../../api/user-api';
import { useFilter } from '../../hooks/use-filter';
import { SubheaderSelect } from '../../components/subheader-select';
import { Filters } from '../../components/filters';
import { FilterSvg } from '../../components/svg/filter-svg';
import { DateRange } from '../../components/date-range';
import { CalendarSvg } from '../../components/svg/calendar-svg';
import { filterQueryParams } from '../../helpers/filter-query-params';
import type { ProjectTable } from './types';
import { AddProject } from './add-project';
import { initialFilters, tableHeaders } from './data';
import { Kanban } from './kanban';

export const ProjectsPage: FC<Record<string, string>> = (props) => {
  const projectType = props.projectType;
  const { data: userMe } = useGetUserMeQuery('');
  const tableRef = useRef(null);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const { scrollHeight } = useScrollHeight(tableRef);
  const { pageSize } = useTypedSelector((state) => state['page-data']);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAddProject, setShowAddProject] = useState(false);
  const [starDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const { data: steps } = useGetProjectsStepsQuery({ params: '' });

  const {
    filters,
    setFilters,
    showFilter,
    setShowFilter,
    resetFilters,
    aplyFilter,
    badges
  } = useFilter({
    initialFilters: initialFilters,
    usersFilterName: 'resp_managers'
  });

  const { data } = useGetProjectsQuery(
    {
      params: `?size=${pageSize}&sale_type=${projectType || ''}&kanban=${
        searchParams.get('kanban') || ''
      }&${filterQueryParams(searchParams.toString(), [
        'contact_id',
        'project_id'
      ])}`
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleSwitchKanban = (value: boolean) => {
    if (value) {
      searchParams.set('kanban', 'true');
      searchParams.delete('current_step');
    } else {
      searchParams.delete('kanban');
    }
    setSearchParams(searchParams);
  };

  const applyDate = () => {
    if (starDate) {
      searchParams.set('created_start', dateToApiFormat(starDate));
      searchParams.set('page', '1');
    }
    if (endDate) {
      searchParams.set('created_end', dateToApiFormat(endDate));
      searchParams.set('page', '1');
    }
    setSearchParams(searchParams);
    setShowCalendar(false);
    setCheckedList([]);
  };

  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
    searchParams.delete('created_start');
    searchParams.delete('created_end');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    setShowCalendar(false);
    setCheckedList([]);
  };

  useEffect(() => {
    if (isValidNewDate(searchParams.get('created_start'))) {
      setStartDate(new Date(searchParams.get('created_start') as string));
    } else {
      setStartDate(null);
    }
    if (isValidNewDate(searchParams.get('created_end'))) {
      setEndDate(new Date(searchParams.get('created_end') as string));
    } else {
      setEndDate(null);
    }
  }, [searchParams]);

  const canView = userMe?.role?.view_project || userMe?.is_superuser;
  const canAdd = userMe?.role?.add_project || userMe?.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <>
      <SubHeader>
        <FlexContainer>
          <PageTitle>
            {projectType === '1'
              ? 'Продажі нові'
              : projectType === '2'
              ? 'Продажі б/у'
              : 'Сервіс'}
          </PageTitle>
          <Counter>{data?.count || 0}</Counter>
          <SubheaderSelect
            show={showCalendar}
            setShow={setShowCalendar}
            icon={CalendarSvg}
            title="Дата створення"
            isActive={!!(starDate || endDate)}
          >
            <DateRange
              startDate={starDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onSubmit={applyDate}
              onReset={resetDate}
            />
          </SubheaderSelect>
          <SubheaderSelect
            show={showFilter}
            setShow={setShowFilter}
            icon={FilterSvg}
            title={`Фільтри (${badges.length})`}
            isActive={!!badges.length}
          >
            <Filters
              setFilters={setFilters}
              filters={
                searchParams.has('kanban')
                  ? filters.filter((item) => item.name !== 'current_step')
                  : filters
              }
              setShow={setShowFilter}
              onCancel={resetFilters}
              onSubmit={aplyFilter}
              disabled={!badges}
            />
          </SubheaderSelect>
          <SwitcherKanban
            isRightOption={searchParams.has('kanban')}
            setIsRightOptins={handleSwitchKanban}
          />
        </FlexContainer>
        {canAdd && (
          <ButtonAdd onClick={() => setShowAddProject(true)}>
            Нова продажа
          </ButtonAdd>
        )}
      </SubHeader>
      <div ref={tableRef}>
        {data && !data.results.length ? (
          <NoData subTitle="" />
        ) : searchParams.has('kanban') && steps ? (
          <Kanban
            steps={steps?.results}
            stepsCount={steps?.results.length || 0}
            projectType={projectType}
          />
        ) : (
          <DataTable<ProjectTable>
            onRowClick={(id) => {
              searchParams.set('project_id', `${id}`);
              setSearchParams(searchParams);
            }}
            customHeight={
              (data?.count || 0) >= pageSize ? scrollHeight - 64 : scrollHeight
            }
            actions={[{ title: 'Видалити', handler: () => {} }]}
            count={data?.count || 0}
            headers={tableHeaders}
            data={
              data?.results.map((item) => ({
                id: +item.id,
                title: item.title,
                description: (
                  <StringCutter lines="2">{item.description}</StringCutter>
                ),
                responsible: item.resp_managers.map((item) => (
                  <p key={item.id}>{item.full_name}</p>
                )),
                contact: item.contact.title,
                current_step: item.current_step?.title,
                current_deadline: dateTableFormat(item.current_deadline || ''),
                budget: item.budget,
                currency: item.currency?.title || ''
              })) || []
            }
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            withOutHover
            withOutCheck
          />
        )}
      </div>
      <Popup
        title="Нова продажа"
        show={showAddProject}
        setShow={setShowAddProject}
      >
        <AddProject saleType={projectType} setShow={setShowAddProject} />
      </Popup>
    </>
  );
};

import {
  useState,
  type Dispatch,
  type FormEventHandler,
  type SetStateAction
} from 'react';
import { useChangeFields } from '../../../hooks/use-change-fields';
import { useCreateContactMutation } from '../../../api/contact-api';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import type { ContactTextFields } from '../contact-form';
import { ContactForm } from '../contact-form';
import type { OptionItem } from '../../../common/types/option-item';

interface AddContactProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const AddContact = ({ setShow }: AddContactProps) => {
  const notification = useNotification();
  const [createContact, { isLoading }] = useCreateContactMutation();
  const [saleType, setSaleType] = useState<OptionItem>({ id: '', title: '' });
  const [contactPersons, setContactPersons] = useState([
    { full_name: '', phone: '' }
  ]);
  const [isPartner, setIsPartner] = useState(false);
  const { fields, handleFieldsChange } = useChangeFields<ContactTextFields>({
    title: '',
    phone: '',
    email: '',
    comment: '',
    city: '',
    address: ''
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const eneredData = Object.entries(fields).filter((item) => item[1]);
    const formData = new FormData();
    eneredData.forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (saleType) {
      formData.append('sale', `${saleType.id}`);
    }
    formData.append('is_partner', `${isPartner}`);
    formData.append(
      'persons',
      JSON.stringify(contactPersons.filter((item) => item.full_name))
    );

    createContact(formData)
      .unwrap()
      .then(() => {
        setShow(false);
        notification({
          type: 'success',
          title: 'Контакт додано!',
          message: 'Ви успішно створили новий контакт.'
        });
      })
      .catch((err) => {
        console.log(err);
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  return (
    <ContactForm
      fields={fields}
      saleType={saleType}
      setSaleType={setSaleType}
      handleFieldsChange={handleFieldsChange}
      isLoading={isLoading}
      setShow={setShow}
      onSubmit={handleSubmit}
      contactPersons={contactPersons}
      setContactPersons={setContactPersons}
      isPartner={isPartner}
      setIsPartner={setIsPartner}
    />
  );
};

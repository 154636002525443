import {
  Fragment,
  type ChangeEventHandler,
  type Dispatch,
  type FormEventHandler,
  type SetStateAction
} from 'react';
import { FormBtns } from '../../../components/form-btns';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { SALE_TYPES } from '../../../common/constants';
import type { OptionItem } from '../../../common/types/option-item';
import { TextArea } from '../../../components/text-area';
import { AddItem } from '../../../components/add-item/indes';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { CheckBox } from '../../../components/checkbox';
import {
  AddContactStyled,
  InputsContainer,
  InputsGroupDoubleTitle,
  PersonPhone,
  TwoColumns
} from './style';

export interface ContactTextFields {
  title: string;
  email: string;
  phone: string;
  city: string;
  address: string;
  comment: string;
}

interface ContactFormProps {
  fields: ContactTextFields;
  onSubmit: FormEventHandler<HTMLFormElement>;
  handleFieldsChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  isLoading: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  saleType: OptionItem;
  setSaleType: Dispatch<SetStateAction<OptionItem>>;
  contactPersons: { full_name: string; phone: string; id?: string | number }[];
  setContactPersons: Dispatch<
    SetStateAction<{ full_name: string; phone: string; id?: string | number }[]>
  >;
  isPartner: boolean;
  setIsPartner: Dispatch<SetStateAction<boolean>>;
}

export const ContactForm = ({
  fields,
  onSubmit,
  handleFieldsChange,
  isLoading,
  setShow,
  saleType,
  setSaleType,
  contactPersons,
  setContactPersons,
  isPartner,
  setIsPartner
}: ContactFormProps) => {
  const handleDeletePerson = (i: number) => {
    if (contactPersons && contactPersons.length === 1) {
      setContactPersons([]);
    } else {
      setContactPersons((state) => state.filter((_, j) => j !== i));
    }
  };

  return (
    <AddContactStyled onSubmit={onSubmit}>
      <InputsContainer>
        <CheckBox
          checked={isPartner}
          setChecked={setIsPartner}
          label="Партнер"
        />
        <div></div>
        <Input
          label="Назва*"
          value={fields.title}
          onChange={handleFieldsChange}
          name="title"
          required
        />
        <Input
          label="Email"
          value={fields.email}
          onChange={handleFieldsChange}
          name="email"
          type="email"
        />
        <Input
          label="Телефон*"
          value={fields.phone}
          onChange={handleFieldsChange}
          type="tel"
          name="phone"
          mask="+38 999 999 99 99"
          required
        />
        <Select
          label="Тип продажі"
          value={saleType}
          setValue={setSaleType}
          options={SALE_TYPES}
        />
        <Input
          label="Місто"
          value={fields.city}
          onChange={handleFieldsChange}
          name="city"
        />
        <Input
          label="Адреса"
          value={fields.address}
          onChange={handleFieldsChange}
          name="address"
        />
        <TwoColumns>
          <TextArea
            label="Коментар"
            value={fields.comment}
            onChange={handleFieldsChange}
            name="comment"
          />
        </TwoColumns>
        <InputsGroupDoubleTitle>Контактні особи</InputsGroupDoubleTitle>
        {contactPersons.map((item, i) => (
          <Fragment key={i}>
            <Input
              label="Імʼя"
              value={item.full_name}
              onChange={(e) =>
                setContactPersons((state) =>
                  state.map((person, j) =>
                    j === i
                      ? { full_name: e.target.value, phone: person.phone }
                      : person
                  )
                )
              }
              name={`person_full_name_${i}`}
            />
            <PersonPhone>
              <Input
                label="Телефон"
                value={item.phone}
                onChange={(e) =>
                  setContactPersons((state) =>
                    state.map((person, j) =>
                      j === i
                        ? { full_name: person.full_name, phone: e.target.value }
                        : person
                    )
                  )
                }
                type="tel"
                name={`person_phone_${i}`}
                mask="+38 999 999 99 99"
              />
              <IconContainer
                m="0 0 11px 0"
                onClick={() => handleDeletePerson(i)}
              >
                <CloseEditSvg />
              </IconContainer>
            </PersonPhone>
          </Fragment>
        ))}
        <AddItem
          onClick={() =>
            setContactPersons((state) => [
              ...state,
              { full_name: '', phone: '' }
            ])
          }
          title="+ Додати контактну особу"
        />
      </InputsContainer>
      <FormBtns disabled={isLoading} onCancel={() => setShow(false)} />
    </AddContactStyled>
  );
};

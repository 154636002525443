import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  useDeleteContactMutation,
  useGetContactsQuery,
  useGetCurrentContactQuery
} from '../../api/contact-api';
import { ButtonAdd } from '../../components/button-add';
import type { HeaderItem } from '../../components/data-table';
import { DataTable } from '../../components/data-table';
import { FlexContainer } from '../../components/flex-container';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import { Popup } from '../../components/popup';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { ConfirmPopup } from '../../components/confirm-popup';
import { NoData } from '../../components/no-data';
import { Counter } from '../../components/counter';
import { SubheaderSelect } from '../../components/subheader-select';
import { CalendarSvg } from '../../components/svg/calendar-svg';
import { DateRange } from '../../components/date-range';
import { Filters } from '../../components/filters';
import { FilterSvg } from '../../components/svg/filter-svg';
import { useGetUserMeQuery } from '../../api/user-api';
import { FilterBadges } from '../../components/filter-badges';
import { dateToApiFormat, isValidNewDate } from '../../helpers/date-helpers';
import { EditColumns } from '../../components/edit-colums';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { setContactColumns } from '../../api/page-data-slice';
import { filterQueryParams } from '../../helpers/filter-query-params';
import { Loader } from '../../components/loader';
import { useFilter } from '../../hooks/use-filter';
import { initialFilters, tableHeaders } from './data';
import { AddContact } from './add-contact';
import type { ContactTable } from './types';

export const ContactsPage = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const { scrollHeight, manuallySetScrollHeight } = useScrollHeight(tableRef);
  const { contactColumns, pageSize, phoneSearchTerm } = useTypedSelector(
    (state) => state['page-data']
  );
  const { data: userMe } = useGetUserMeQuery('');
  const [searchParams, setSearhParams] = useSearchParams();
  const notification = useNotification();
  const [showAllBadges, setShowAllBadges] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const {
    filters,
    setFilters,
    showFilter,
    setShowFilter,
    resetFilters,
    aplyFilter,
    badges
  } = useFilter({
    initialFilters: initialFilters
  });

  const isPartner = pathname === '/partners';
  const { data, isLoading, isFetching, isSuccess } = useGetContactsQuery(
    {
      params: `?size=${pageSize}&phone_search=${encodeURIComponent(
        phoneSearchTerm
      )}${isPartner ? '&is_partner=true' : ''}&${filterQueryParams(
        searchParams.toString(),
        'contact_id'
      )}`
    },
    { refetchOnMountOrArgChange: true }
  );
  const [showAddContact, setShowAddContact] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [checkedContactId, setCheckedContactId] = useState<number | null>(null);
  const [starDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [deleteContact] = useDeleteContactMutation();
  const { data: checkedContact } = useGetCurrentContactQuery(
    { id: checkedContactId },
    { skip: !checkedContactId, refetchOnMountOrArgChange: true }
  );

  const handleDelete = () => {
    deleteContact({ ids: checkedList })
      .unwrap()
      .then(() => {
        setCheckedList([]);
        setConfirmMessage('');
        setShowConfirm(false);
        setCheckedContactId(null);
        notification({
          type: 'success',
          title:
            checkedList.length === 1
              ? 'Контакт видалено'
              : `${checkedList.length} Контактів видалено`,
          message: ''
        });
        const currentPage = searchParams.get('page');
        if (
          checkedList.length >= (data?.results.length || 0) &&
          currentPage &&
          currentPage !== '1' &&
          !isNaN(+currentPage)
        ) {
          searchParams.set('page', `${+currentPage - 1}`);
          setSearhParams(searchParams);
        }
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleConfirm = () => {
    setShowConfirm(true);
    if (checkedList.length === 1) {
      if (checkedContact && checkedList[0] === checkedContactId) {
        setConfirmMessage(
          `Чи дійсно бажаєте видалити контакт ${checkedContact.title}`
        );
      } else {
        setCheckedContactId(checkedList[0]);
      }

      return;
    }
    setConfirmMessage(
      `Чи дійсно бажаєте видалити ${checkedList.length} контактів`
    );
  };

  useEffect(() => {
    if (checkedContact) {
      setConfirmMessage(
        `Чи дійсно бажаєте видалити контакт ${checkedContact.title}`
      );
    }
  }, [checkedContact]);

  const applyDate = () => {
    if (starDate) {
      searchParams.set('created_start', dateToApiFormat(starDate));
      searchParams.set('page', '1');
    }
    if (endDate) {
      searchParams.set('created_end', dateToApiFormat(endDate));
      searchParams.set('page', '1');
    }
    setSearhParams(searchParams);
    setShowCalendar(false);
    setCheckedList([]);
  };

  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
    searchParams.delete('created_start');
    searchParams.delete('created_end');
    searchParams.set('page', '1');
    setSearhParams(searchParams);
    setShowCalendar(false);
    setCheckedList([]);
  };

  const setCustomColumns = useCallback(
    (columns: HeaderItem<ContactTable>[] | null) => {
      dispatch(setContactColumns(columns));
    },
    [dispatch]
  );

  const resetCustomColumns = useCallback(() => {
    dispatch(setContactColumns(null));
  }, [dispatch]);

  useEffect(() => {
    if (isValidNewDate(searchParams.get('created_start'))) {
      setStartDate(new Date(searchParams.get('created_start') as string));
    } else {
      setStartDate(null);
    }
    if (isValidNewDate(searchParams.get('created_end'))) {
      setEndDate(new Date(searchParams.get('created_end') as string));
    } else {
      setEndDate(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isSuccess) {
      manuallySetScrollHeight();
    }
  }, [badges.length, showAllBadges, manuallySetScrollHeight, isSuccess]);

  const canView = userMe?.role?.view_contact || userMe?.is_superuser;

  const columns =
    contactColumns?.filter((item) => item.checked) || tableHeaders;

  if (!userMe || !canView) {
    return null;
  }

  const canRemove = userMe.role?.delete_contact || userMe.is_superuser;
  const canAdd = userMe.role?.add_contact || userMe.is_superuser;

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <SubHeader>
        <FlexContainer>
          <PageTitle>{isPartner ? 'Партнери' : 'Контакти'}</PageTitle>
          <Counter>{data?.count}</Counter>
          <SubheaderSelect
            show={showCalendar}
            setShow={setShowCalendar}
            icon={CalendarSvg}
            title="Дата створення"
            isActive={!!(starDate || endDate)}
          >
            <DateRange
              startDate={starDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onSubmit={applyDate}
              onReset={resetDate}
            />
          </SubheaderSelect>
          <SubheaderSelect
            show={showFilter}
            setShow={setShowFilter}
            icon={FilterSvg}
            title={`Фільтри (${badges.length})`}
            isActive={!!badges.length}
          >
            <Filters
              setFilters={setFilters}
              filters={filters}
              setShow={setShowFilter}
              onCancel={resetFilters}
              onSubmit={aplyFilter}
              disabled={!badges}
            />
          </SubheaderSelect>
          <EditColumns<ContactTable>
            onSubmit={setCustomColumns}
            initialHeaders={tableHeaders}
            columns={contactColumns}
            onReset={resetCustomColumns}
          />
        </FlexContainer>
        <FlexContainer>
          {canAdd && !isPartner && (
            <ButtonAdd onClick={() => setShowAddContact(true)}>
              Новий контакт
            </ButtonAdd>
          )}
        </FlexContainer>
      </SubHeader>
      <FilterBadges
        showAll={showAllBadges}
        setShowAll={setShowAllBadges}
        badges={badges}
      />
      {(!userMe?.role?.view_contact && !userMe?.is_superuser) ||
      (data && !data.results.length) ? (
        <NoData subTitle="" />
      ) : (
        <div ref={tableRef}>
          <DataTable<ContactTable>
            onRowClick={(id) => {
              searchParams.set('contact_id', `${id}`);
              setSearhParams(searchParams);
            }}
            isFetching={isLoading || isFetching}
            actions={
              canRemove ? [{ title: 'Видалити', handler: handleConfirm }] : []
            }
            customHeight={
              data.count >= pageSize ? scrollHeight - 64 : scrollHeight
            }
            headers={columns}
            data={data.results.map((item) => ({
              ...item,
              sale: item.sale.title || '',
              is_partner: item.is_partner ? 'Так' : 'Ні',
              contact_persons: (
                <>
                  {item.contact_persons.map((person, i) => (
                    <div key={i}>
                      {person.full_name}
                      <br />
                      {person.phone}
                    </div>
                  ))}
                </>
              )
            }))}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            count={data.count}
            withCheckAllPages
            params={`?${filterQueryParams(
              searchParams.toString(),
              'contact_id'
            )}`}
          />
          <ConfirmPopup
            show={showConfirm}
            title={confirmMessage}
            setShow={setShowConfirm}
            onSubmit={handleDelete}
          />
        </div>
      )}
      <Popup
        show={showAddContact}
        maxContentWidth="680px"
        setShow={setShowAddContact}
        title="Новий контакт"
        outsideOnlyEscape
      >
        <AddContact setShow={setShowAddContact} />
      </Popup>
    </>
  );
};

import { useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useSearchParams } from 'react-router-dom';
import type { Task } from '../../../models/task';
import { DragKanbanSvg } from '../../../components/svg/drrag-kanban-svg';
import { StringCutter } from '../../../components/string-cutter';
import { kanbanDate } from '../../../helpers/date-helpers';
import {
  CardDate,
  CardHeader,
  ContactName,
  DragTrigger,
  KanbanCardStyled,
  PhoneAndEmail,
  PhoneAndEmailItem,
  RespManager
} from './style';

interface KanbanCardProps {
  task: Task;
  colIdx: number;
  isFetching?: boolean;
  color?: string;
}

export const KanbanCard = ({
  task,
  colIdx,
  isFetching,
  color
}: KanbanCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: task.id || '',
    data: task,
    disabled: isFetching
  });

  const handleShowTask = () => {
    searchParams.set('task_id', task.id.toString());
    setSearchParams(searchParams);
  };

  return isDragging ? (
    <div style={{ height: '220px' }}></div>
  ) : (
    <KanbanCardStyled
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      colIdx={colIdx}
      ref={setNodeRef}
      $color={color}
    >
      <CardHeader>
        <DragTrigger {...attributes} {...listeners}>
          {isHover && !isFetching && <DragKanbanSvg />}
        </DragTrigger>
      </CardHeader>
      <ContactName onClick={handleShowTask}>{task.title}</ContactName>
      <PhoneAndEmail>
        <PhoneAndEmailItem>
          <StringCutter lines="2">{task.description}</StringCutter>
        </PhoneAndEmailItem>
      </PhoneAndEmail>
      <CardDate>Проект:</CardDate>
      <PhoneAndEmailItem>
        <StringCutter lines="2">{task.project?.title}</StringCutter>
      </PhoneAndEmailItem>
      <CardDate>
        Дедлайн: {task.deadline ? kanbanDate(task.deadline) : ''}
      </CardDate>
      <RespManager colIdx={colIdx}>
        {task.resp_managers?.map((item) => (
          <p key={item.id}>{item.full_name}</p>
        ))}
      </RespManager>
    </KanbanCardStyled>
  );
};

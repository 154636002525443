import { useCallback, useEffect, useState } from 'react';
import {
  useEditRoleMutation,
  useGetCurrentRoleQuery,
  useGetUserMeQuery
} from '../../../../api/user-api';
import type { Permission } from '../../../../common/types/permission';
import { CheckBox } from '../../../../components/checkbox';
import { ConfirmPanel } from '../../../../components/confirm-panel';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';
import {
  TableHeaderItem,
  TableRow,
  TableRowItem,
  UsersAccessTableStyled
} from './style';

const row: (keyof Permission)[] = ['view', 'add', 'change', 'delete'];

interface UsersAccesTableProps {
  id: string | number;
  roleName?: string;
}

export const UsersAccesTable = ({ id }: UsersAccesTableProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [userPermissions, setUserPermissions] = useState<Permission>({
    view: false,
    add: false,
    change: false,
    delete: false
  });
  const [rolePermissions, setRolePermissions] = useState<Permission>({
    view: false,
    add: false,
    change: false,
    delete: false
  });
  const [contactPermissions, setContactPermissions] = useState<Permission>({
    view: false,
    add: false,
    change: false,
    delete: false,
    export: false,
    import: false
  });
  const [projectPermissions, setProjectPermissions] = useState<Permission>({
    view: false,
    add: false,
    change: false,
    delete: false,
    archive: false
  });
  const [partnerPermissions, setPartnerPermissions] = useState<Permission>({
    view: false,
    add: false,
    change: false,
    delete: false,
    archive: false
  });
  const [eventPermissions, setEventPermissions] = useState<Permission>({
    view: false,
    add: false,
    change: false,
    delete: false,
    archive: false
  });

  const { data, isFetching } = useGetCurrentRoleQuery(
    { id },
    { skip: !id, refetchOnMountOrArgChange: true }
  );
  const [editRole, { isLoading }] = useEditRoleMutation();

  const userPermissionsHasBeenChanged =
    data &&
    JSON.stringify(userPermissions) !==
      JSON.stringify(data?.front_permissions.user);
  const rolePermissionsHasBeenChanged =
    data &&
    JSON.stringify(rolePermissions) !==
      JSON.stringify(data?.front_permissions.role);
  const contactPermissionsHasBeenChanged =
    data &&
    JSON.stringify(contactPermissions) !==
      JSON.stringify(data?.front_permissions.contact);
  const eventPermissionsHasBeenChanged =
    data &&
    JSON.stringify(eventPermissions) !==
      JSON.stringify(data?.front_permissions.event);
  const projectPermissionsHasBeenChanged =
    data &&
    JSON.stringify(projectPermissions) !==
      JSON.stringify(data?.front_permissions.project);
  const partnerPermissionsHasBeenChanged =
    data &&
    JSON.stringify(partnerPermissions) !==
      JSON.stringify(data?.front_permissions.partner);

  useEffect(() => {
    if (data) {
      setUserPermissions(data.front_permissions.user);
      setRolePermissions(data.front_permissions.role);
      setContactPermissions(data.front_permissions.contact);
      setEventPermissions(data.front_permissions.event);
      setProjectPermissions(data.front_permissions.project);
      setPartnerPermissions(data.front_permissions.partner);
    }
  }, [data]);

  const handleCancel = useCallback(() => {
    if (data) {
      setUserPermissions(data.front_permissions.user);
      setRolePermissions(data?.front_permissions.role);
      setContactPermissions(data.front_permissions.contact);
      setEventPermissions(data.front_permissions.event);
      setProjectPermissions(data.front_permissions.project);
      setPartnerPermissions(data.front_permissions.partner);
    }
  }, [data]);

  const handleSubmit = () => {
    editRole({
      id,
      body: {
        add_user: userPermissions.add,
        view_user: userPermissions.view,
        change_user: userPermissions.change,
        delete_user: userPermissions.delete,
        add_role: rolePermissions.add,
        view_role: rolePermissions.view,
        change_role: rolePermissions.change,
        delete_role: rolePermissions.delete,
        add_contact: contactPermissions.add,
        view_contact: contactPermissions.view,
        change_contact: contactPermissions.change,
        delete_contact: contactPermissions.delete,
        export_contact: contactPermissions.export,
        import_contact: contactPermissions.import,
        add_event: eventPermissions.add,
        view_event: eventPermissions.view,
        change_event: eventPermissions.change,
        delete_event: eventPermissions.delete,
        add_partner: partnerPermissions.add,
        view_partner: partnerPermissions.view,
        change_partner: partnerPermissions.change,
        delete_partner: partnerPermissions.delete,
        add_project: projectPermissions.add,
        view_project: projectPermissions.view,
        change_project: projectPermissions.change,
        delete_project: projectPermissions.delete,
        archive_project: projectPermissions.archive
      }
    })
      .unwrap()
      .then(() => {})
      .catch((err) => {
        notification({
          title: 'Помилка',
          type: 'error',
          message: getApiError(err)
        });
      });
  };

  const handleChangeUser = (checked: boolean, item: keyof Permission) => {
    if (item === 'add' && !rolePermissions.view && checked) {
      setRolePermissions((state) => ({ ...state, view: true }));
    }
    setUserPermissions((state) => ({ ...state, [item]: checked }));
  };

  const handleChangeRole = (checked: boolean, item: keyof Permission) => {
    if (item === 'view' && userPermissions.add && !checked) {
      setUserPermissions((state) => ({ ...state, add: false }));
    }
    setRolePermissions((state) => ({ ...state, [item]: checked }));
  };

  const showConfirmPanel =
    !isFetching &&
    (userPermissionsHasBeenChanged ||
      rolePermissionsHasBeenChanged ||
      contactPermissionsHasBeenChanged ||
      eventPermissionsHasBeenChanged ||
      projectPermissionsHasBeenChanged ||
      partnerPermissionsHasBeenChanged);

  return (
    <UsersAccessTableStyled showConfirmPanel={showConfirmPanel}>
      <TableRow>
        <TableHeaderItem>Дані</TableHeaderItem>
        <TableHeaderItem>Перегляд</TableHeaderItem>
        <TableHeaderItem>Додавання</TableHeaderItem>
        <TableHeaderItem>Редагування</TableHeaderItem>
        <TableHeaderItem>Видалення</TableHeaderItem>
      </TableRow>
      <TableRow>
        <TableRowItem>Співробітник</TableRowItem>
        {row.map((item) => (
          <TableRowItem key={item}>
            {userPermissions[item] === undefined ? (
              ''
            ) : (
              <CheckBox
                disabled={
                  !(userMe?.role?.change_role || userMe?.is_superuser) ||
                  isFetching ||
                  isLoading
                }
                checked={!!userPermissions[item]}
                setChecked={(checked) => handleChangeUser(checked, item)}
              />
            )}
          </TableRowItem>
        ))}
      </TableRow>
      <TableRow>
        <TableRowItem>Роль</TableRowItem>
        {row.map((item) => (
          <TableRowItem key={item}>
            {rolePermissions[item] === undefined ? (
              ''
            ) : (
              <CheckBox
                disabled={
                  !(userMe?.role?.change_role || userMe?.is_superuser) ||
                  isFetching ||
                  isLoading
                }
                checked={!!rolePermissions[item]}
                setChecked={(checked) => handleChangeRole(checked, item)}
              />
            )}
          </TableRowItem>
        ))}
      </TableRow>
      <TableRow>
        <TableRowItem>Контакт</TableRowItem>
        {row.map((item) => (
          <TableRowItem key={item}>
            {contactPermissions[item] === undefined ? (
              ''
            ) : (
              <CheckBox
                disabled={
                  !(userMe?.role?.change_role || userMe?.is_superuser) ||
                  isFetching ||
                  isLoading
                }
                checked={!!contactPermissions[item]}
                setChecked={(checked) =>
                  setContactPermissions((state) => ({
                    ...state,
                    [item]: checked
                  }))
                }
              />
            )}
          </TableRowItem>
        ))}
      </TableRow>
      <TableRow>
        <TableRowItem>Проєкт</TableRowItem>
        {row.map((item) => (
          <TableRowItem key={item}>
            {projectPermissions[item] === undefined ? (
              ''
            ) : (
              <CheckBox
                disabled={
                  !(userMe?.role?.change_role || userMe?.is_superuser) ||
                  isFetching ||
                  isLoading
                }
                checked={!!projectPermissions[item]}
                setChecked={(checked) =>
                  setProjectPermissions((state) => ({
                    ...state,
                    [item]: checked
                  }))
                }
              />
            )}
          </TableRowItem>
        ))}
      </TableRow>

      {!isFetching && showConfirmPanel && (
        <ConfirmPanel
          cancelDisabled={isLoading || isFetching}
          disabled={isLoading}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </UsersAccessTableStyled>
  );
};

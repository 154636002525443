import { TASK_STATUSES } from '../../common/constants';
import type { Filter } from '../../common/types/filters';
import type { HeaderItem } from '../../components/data-table';
import type { TaskTable } from './types';

export const tableHeaders: HeaderItem<TaskTable>[] = [
  { id: 'id', title: 'ID' },
  { id: 'name', title: 'Назва', width: '250px' },
  { id: 'description', title: 'Опис' },
  { id: 'deadline', title: 'Дедлайн' },
  { id: 'project', title: 'Продажа' },
  { id: 'status', title: 'Статус', width: '170px' },
  { id: 'high_priority', title: 'Важливе' },
  { id: 'responsible', title: 'Відповідальний' },
  { id: 'created_by', title: 'Завдання поставив' },
  { id: 'created_at', title: 'Дата створення' }
];

export const initialFilters: Filter[] = [
  {
    type: 'checkbox',
    checkList: [],
    title: 'Відповідальний',
    name: 'resp_managers'
  },
  {
    type: 'checkbox',
    checkList: TASK_STATUSES.map((item) => ({
      id: item.id,
      title: item.title,
      checked: false
    })),
    title: 'Статус',
    name: 'status'
  },
  {
    type: 'checkbox',
    isSingly: true,
    checkList: [
      {
        id: 'high_priority',
        checked: false,
        title: 'Важливе'
      }
    ],
    title: 'Пріорітет',
    name: 'high_priority'
  },
  {
    type: 'checkbox',
    checkList: [],
    title: 'Продажа',
    name: 'project'
  }
];

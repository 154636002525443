import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { Filter } from '../common/types/filters';
import {
  setFiltersFromQueryString,
  transformFilterToObj
} from '../components/filters/helpers';
import { useGetUserRolesQuery, useGetUsersQuery } from '../api/user-api';
import { setBadges } from '../components/filter-badges/helpers';
import { useGetProjectsQuery } from '../api/projects-api';
import type { OptionItem } from '../common/types/option-item';

interface UseFilterArgs {
  initialFilters: Filter[];
  usersFilterName?: string;
  rolesFilterName?: string;
  projectsFilterName?: string;
  steps?: OptionItem[];
  setCheckedList?: Dispatch<SetStateAction<number[]>>;
}

export const useFilter = ({
  initialFilters,
  usersFilterName,
  rolesFilterName,
  projectsFilterName,
  setCheckedList
}: UseFilterArgs) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Filter[]>(initialFilters);
  const [initialFiltersServerData, setInitialFilterWithServerData] =
    useState<Filter[]>(initialFilters);
  const [showFilter, setShowFilter] = useState(false);

  const { data: users } = useGetUsersQuery(
    { params: '?size=100' },
    { skip: !usersFilterName }
  );
  const { data: roles } = useGetUserRolesQuery('', { skip: !rolesFilterName });
  const { data: projects } = useGetProjectsQuery(
    { params: '?size=100' },
    {
      skip: !projectsFilterName
    }
  );

  const resetFilters = useCallback(() => {
    Object.keys(transformFilterToObj(filters)).forEach((item) => {
      searchParams.delete(item);
    });
    setSearchParams(searchParams);
    setShowFilter(false);
    setFilters(initialFiltersServerData);
    if (setCheckedList) {
      setCheckedList([]);
    }
  }, [
    setSearchParams,
    filters,
    searchParams,
    initialFiltersServerData,
    setCheckedList
  ]);

  const aplyFilter = () => {
    if (searchParams.has('kanban')) {
      setSearchParams({ ...transformFilterToObj(filters), kanban: 'true' });
    } else {
      setSearchParams(transformFilterToObj(filters));
    }

    setShowFilter(false);
    if (setCheckedList) {
      setCheckedList([]);
    }
  };

  useEffect(() => {
    let tempFiltersWithServerData = initialFilters;
    if (usersFilterName && users) {
      const addUsersToFilters = () =>
        initialFilters.map((item) =>
          item.name === usersFilterName
            ? {
                ...item,
                checkList: users.results.map((item) => ({
                  id: item.id,
                  title: `${item.last_name || ''} ${item.first_name || ''} ${
                    item.patronymic || ''
                  }`,
                  checked: false
                }))
              }
            : item
        );
      tempFiltersWithServerData = addUsersToFilters();
    }

    if (rolesFilterName && roles) {
      const addRolesToFilters = () =>
        tempFiltersWithServerData.map((item) =>
          item.name === rolesFilterName
            ? {
                ...item,
                checkList: roles.results.map((item) => ({
                  id: item.id,
                  checked: false,
                  title: item.name,
                  name: `${item.id}`
                }))
              }
            : item
        );
      tempFiltersWithServerData = addRolesToFilters();
    }

    if (projectsFilterName && projects) {
      const addProjectsToFilters = () =>
        tempFiltersWithServerData.map((item) =>
          item.name === projectsFilterName
            ? {
                ...item,
                checkList: projects.results.map((item) => ({
                  id: item.id,
                  checked: false,
                  title: item.title,
                  name: `${item.id}`
                }))
              }
            : item
        );
      tempFiltersWithServerData = addProjectsToFilters();
    }

    // if (steps) {
    //   const addStepsToFilters = () =>
    //     tempFiltersWithServerData.map((item) => {
    //       if (item.name === 'current_step') {
    //         return {
    //           ...item,
    //           checkList:
    //             steps.map((item) => ({
    //               id: item.id,
    //               title: item.title,
    //               checked: false
    //             })) || []
    //         };
    //       }

    //       return item;
    //     });
    //   tempFiltersWithServerData = addStepsToFilters();
    // }

    setFilters(() =>
      setFiltersFromQueryString(
        searchParams.toString(),
        tempFiltersWithServerData
      )
    );
    setInitialFilterWithServerData(() =>
      setFiltersFromQueryString(
        searchParams.toString(),
        tempFiltersWithServerData
      )
    );
  }, [
    initialFilters,
    projects,
    projectsFilterName,
    roles,
    rolesFilterName,
    searchParams,
    users,
    usersFilterName
  ]);

  const filtersLength = setBadges(filters, '').length;
  const badges = setBadges(initialFiltersServerData, searchParams.toString());

  return {
    filters,
    setFilters,
    initialFilters: initialFiltersServerData,
    resetFilters,
    aplyFilter,
    showFilter,
    setShowFilter,
    badges,
    filtersLength
  };
};

import styled from 'styled-components';
import { centerFlex } from '../../../style/helpers';

export const KanbanCardStyled = styled.div<{
  colIdx: number;
  isProcessed?: boolean;
  $color?: string;
}>`
  margin-top: 20px;
  position: relative;
  padding: 16px 20px;
  border-radius: 4px 10px 10px 4px;
  box-shadow: 3px 2px 10px 0px rgba(63, 81, 126, 0.05),
    -2px -3px 10px 0px rgba(63, 81, 126, 0.05);
  background: ${(p) =>
    p.isProcessed ? p.theme.colors.processed : p.theme.colors.white};
  :hover {
    background: rgb(255, 249, 237);
  }
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 15px 0px 0px 15px;
    background: #e5a090;
    box-shadow: 3px 2px 10px 0px rgba(63, 81, 126, 0.05),
      -2px -3px 10px 0px rgba(63, 81, 126, 0.05);
    background: ${(p) =>
      p.$color ? p.$color : p.theme.colors.steps[p.colIdx]};
  }
  :first-child {
    margin-top: 0;
  }
`;

export const CardHeader = styled.div<{ isProcessed?: boolean }>`
  min-height: 20px;
  display: flex;
  justify-content: ${(p) => (p.isProcessed ? 'flex-end' : 'space-between')};
  align-items: center;
  gap: 10px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RadioBtn = styled.div<{ isActive: boolean; colIdx: number }>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1.5px solid
    ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.blue.dark)};
  ${centerFlex}
  cursor: pointer;
  :hover {
    border: 1.5px solid
      ${(p) =>
        p.isActive ? p.theme.colors.white : p.theme.colors.steps[p.colIdx]};
  }
  :after {
    content: '';
    display: ${(p) => (p.isActive ? 'block' : 'none')};
    width: 6px;
    height: 6px;
    background: ${(p) => p.theme.colors.white};
    border-radius: 100%;
  }
`;

export const SatisfactionIcon = styled.div<{
  colIdx: number;
  isProcessed?: boolean;
}>`
  position: relative;
  ${centerFlex}
  svg {
    path {
      fill: ${(p) =>
        p.isProcessed ? p.theme.colors.white : p.theme.colors.blue.dark};
    }
  }
  :hover {
    svg {
      path {
        fill: ${(p) =>
          p.isProcessed
            ? p.theme.colors.white
            : p.theme.colors.steps[p.colIdx]};
      }
    }
  }
`;

export const CardDate = styled.div<{ isProcessed?: boolean }>`
  margin-top: 16px;
  font-size: 12px;
  font-weight: 500;
  color: ${(p) =>
    p.isProcessed ? p.theme.colors.white : p.theme.colors.grey.light};
`;

export const ContactName = styled.div<{ isProcessed?: boolean }>`
  margin-top: 12px;
  color: ${(p) =>
    p.isProcessed ? p.theme.colors.white : p.theme.colors.blue.dark};
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  cursor: pointer;
`;

export const PhoneAndEmail = styled.div`
  margin-top: 20px;
`;

export const PhoneAndEmailItem = styled.div<{ isProcessed?: boolean }>`
  margin-top: 8px;
  font-size: 14px;
  color: ${(p) =>
    p.isProcessed ? p.theme.colors.white : p.theme.colors.blue.dark};
  opacity: 0.7;
  :first-child {
    margin-top: 0;
  }
`;

export const RespManager = styled.div<{
  colIdx: number;
  isProcessed?: boolean;
}>`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  p {
    margin-top: 5px;
    :first-child {
      margin-top: 0;
    }
  }
  color: ${(p) =>
    p.isProcessed ? p.theme.colors.white : p.theme.colors.steps[p.colIdx]};
`;

export const DragTrigger = styled.div<{ isProcessed?: boolean }>`
  cursor: move;
  svg {
    path {
      fill: ${(p) =>
        p.isProcessed ? p.theme.colors.white : p.theme.colors.blue.dark};
    }
  }
`;

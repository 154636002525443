import styled from 'styled-components';

export const AddProjectStyled = styled.form`
  padding-top: 20px;
`;

export const InputsContainer = styled.div`
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: start;
`;

export const ManagerItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 12px;
  align-items: end;
  svg {
    max-width: 11px;
    path {
      fill: ${(p) => p.theme.colors.grey.light};
    }
  }
`;

export const GroupTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const BudgetWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 5px;
`;

export const DoubleRow = styled.div`
  grid-row: span 2;
`;

import styled from 'styled-components';

export const AddContactStyled = styled.form`
  padding-top: 20px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 20px));
  gap: 40px;
  row-gap: 20px;
  padding-bottom: 30px;
  align-items: start;
`;

export const TwoColumns = styled.div`
  grid-column: span 2;
`;

export const InputsGroupTitle = styled.p<{ fz?: string }>`
  font-weight: 600;
  font-size: ${(p) => p.fz || '16px'};
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const InputsGroupDoubleTitle = styled(InputsGroupTitle)`
  grid-column: span 2;
`;

export const PersonPhone = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  gap: 5px;
  align-items: end;
`;

import { useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useSearchParams } from 'react-router-dom';
import { DragKanbanSvg } from '../svg/drrag-kanban-svg';
import { kanbanDate } from '../../helpers/date-helpers';
import type { Project } from '../../models/project';
import { StringCutter } from '../string-cutter';
import {
  CardDate,
  CardHeader,
  ContactName,
  DragTrigger,
  KanbanCardStyled,
  PhoneAndEmail,
  PhoneAndEmailItem,
  RespManager
} from './style';

interface KanbanCardProps {
  project: Project;
  colIdx: number;
  isFetching?: boolean;
}

export const KanbanCard = ({
  project,
  colIdx,
  isFetching
}: KanbanCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: project.id || '',
    data: project,
    disabled: isFetching
  });

  const handleShowProject = () => {
    searchParams.set('project_id', project.id.toString());
    setSearchParams(searchParams);
  };

  const handleShowContact = () => {
    searchParams.set('contact_id', project.contact.id.toString());
    setSearchParams(searchParams);
  };

  return isDragging ? (
    <div style={{ height: '220px' }}></div>
  ) : (
    <KanbanCardStyled
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      colIdx={colIdx}
      ref={setNodeRef}
    >
      <CardHeader>
        <DragTrigger {...attributes} {...listeners}>
          {isHover && !isFetching && <DragKanbanSvg />}
        </DragTrigger>
      </CardHeader>
      <ContactName onClick={handleShowProject}>{project.title}</ContactName>
      <PhoneAndEmail>
        <PhoneAndEmailItem>
          <StringCutter lines="2">{project.description}</StringCutter>
        </PhoneAndEmailItem>
      </PhoneAndEmail>
      <CardDate>
        Дедлайн:{' '}
        {project.current_deadline ? kanbanDate(project.current_deadline) : ''}
      </CardDate>
      <ContactName onClick={handleShowContact}>
        {project.contact.title}
      </ContactName>
      <PhoneAndEmailItem>{project.contact.phone}</PhoneAndEmailItem>
      <RespManager colIdx={colIdx}>
        {project.resp_managers?.map((item) => (
          <p key={item.id}>
            {item.last_name} {item.first_name} {item.patronymic}
          </p>
        ))}
      </RespManager>
    </KanbanCardStyled>
  );
};

import { useState } from 'react';
import { TabItem } from '../../../../components/tab-item';
import { History } from '../../../../components/history';
import { ViewProjects } from '../../../../components/view-projects';
import { PageFiles } from '../../../../components/page-files';
import type { User } from '../../../../models/user';
import type { ServerFile } from '../../../../common/types/server-file';
import {
  useEditUserMutation,
  useGetUserActivitiQuery
} from '../../../../api/user-api';
import type { Project } from '../../../../models/project';
import type { IEvent } from '../../../../models/event';
import { AppRoute } from '../../../../common/enums/app/app-route.enum';
import { TabContent, TabsStyled } from './style';
import { tabList } from './data';

interface CurrentUserTabsProps {
  files?: ServerFile[];
  passportFiles?: ServerFile[];
  innFiles?: ServerFile[];
  userId: string | number;
  projects: Project[];
  events: IEvent[];
}

export const CurrentUserTabs = ({
  files,
  passportFiles,
  innFiles,
  userId,
  projects,
  events
}: CurrentUserTabsProps) => {
  const [currentTab, setCurrentTab] = useState('views');
  const [editUser, { isLoading }] = useEditUserMutation();
  const { data: activities, isLoading: activitiesLoading } =
    useGetUserActivitiQuery({ id: userId }, { skip: !userId });

  const renderContent = () => {
    switch (currentTab) {
      case 'views':
        return (
          <div style={{ padding: '0 20px' }}>
            <History
              isUser
              histories={activities || { activities: [] }}
              isLoading={activitiesLoading}
            />
            <ViewProjects
              title="Проєкти"
              items={projects?.map((item) => ({
                title: item.title,
                link: `${AppRoute.PROJECTS}/${item.id}`
              }))}
            />
            <ViewProjects
              title="Події"
              items={events?.map((item) => ({
                title: item.name,
                link: `${AppRoute.PROJECTS}${AppRoute.EVENTS}/${item.id}`
              }))}
            />
          </div>
        );
      case 'files':
        return (
          <PageFiles<User>
            contactId={userId}
            files={files}
            passportFiles={passportFiles}
            innFiles={innFiles}
            editTrigger={editUser}
            isLoading={isLoading}
            isDeleteLoading={isLoading}
          />
        );
      default:
        return (
          <div style={{ padding: '0 20px' }}>
            <History
              isUser
              histories={activities || { activities: [] }}
              isLoading={activitiesLoading}
            />
            <ViewProjects
              title="Проєкти"
              items={projects?.map((item) => ({
                title: item.title,
                link: `${AppRoute.PROJECTS}/${item.id}`
              }))}
            />
            <ViewProjects
              title="Події"
              items={events?.map((item) => ({
                title: item.name,
                link: `${AppRoute.PROJECTS}${AppRoute.EVENTS}/${item.id}`
              }))}
            />
          </div>
        );
    }
  };

  return (
    <>
      <TabsStyled>
        {tabList.map((item, i) => (
          <TabItem
            onClick={setCurrentTab}
            $isActive={item.id === currentTab}
            {...item}
            key={i}
          />
        ))}
      </TabsStyled>
      <TabContent>{renderContent()}</TabContent>
    </>
  );
};

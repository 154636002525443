import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { TabItem } from '../../../../components/tab-item';
import type { ServerFile } from '../../../../common/types/server-file';
import { PageFiles } from '../../../../components/page-files';
import {
  useDeleteContactCallMutation,
  useDeleteContactMeetMutation,
  useDeleteContactNoteMutation,
  useEditContactCallMutation,
  useEditContactMeetMutation,
  useEditContactMutation,
  useEditContactNoteMutation
} from '../../../../api/contact-api';
import type { Contact } from '../../../../models/contact';
import { Activity } from '../../../../components/activity';
import type { Note } from '../../../../models/note';
import type { Call } from '../../../../models/call';
import type { Meet } from '../../../../models/meet';
import type { Project } from '../../../../models/project';
import type { IEvent } from '../../../../models/event';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { isFieldInProject } from '../../../../helpers/is-field-allowed';
import { TabContent, TabsStyled } from './style';
import { activityTabs, tabList } from './data';

interface CurrentContactTabsProps {
  files?: ServerFile[] | null;
  passportFiles?: ServerFile[] | null;
  innFiles?: ServerFile[] | null;
  contactId: string | number;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  notes: Note[];
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  calls: Call[];
  setShowNewMeet: Dispatch<SetStateAction<boolean>>;
  meets: Meet[];
  contactFullName: string;
  phone?: string;
  projects?: Project[] | null;
  events?: IEvent[] | null;
  dbFiles?: ServerFile[];
  allowedFields?: string[];
}

export const CurrentContactTabs = ({
  files,
  contactId,
  passportFiles,
  innFiles,
  setShowNewNote,
  setShowNewCall,
  setShowNewMeet,
  notes,
  calls,
  meets,
  contactFullName,
  phone,
  dbFiles,
  allowedFields
}: CurrentContactTabsProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [currentTab, setCurrentTab] = useState('activity');
  const [editContact, { isLoading }] = useEditContactMutation();
  const [deleteCall] = useDeleteContactCallMutation();
  const [editCall] = useEditContactCallMutation();
  const [editMeet] = useEditContactMeetMutation();
  const [deleteMeet] = useDeleteContactMeetMutation();
  const [editNote] = useEditContactNoteMutation();
  const [deleteNote] = useDeleteContactNoteMutation();

  if (!userMe) {
    return null;
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'activity':
        return (
          <Activity
            notes={notes}
            tabs={activityTabs}
            setShowNewNote={setShowNewNote}
            calls={calls}
            setShowNewCall={setShowNewCall}
            contactFullName={contactFullName}
            contactId={contactId}
            meets={meets}
            setShowNewMeet={setShowNewMeet}
            phone={phone}
            editCall={editCall}
            deleteCall={deleteCall}
            deleteMeet={deleteMeet}
            editMeet={editMeet}
            editNote={editNote}
            deleteNote={deleteNote}
          />
        );
      case 'files':
        return (
          <PageFiles<Contact>
            contactId={contactId}
            files={files || []}
            passportFiles={passportFiles || []}
            innFiles={innFiles || []}
            editTrigger={editContact}
            isLoading={isLoading}
            dbFiles={dbFiles}
            isDeleteLoading={isLoading}
            filesAddKey="files"
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <TabsStyled>
        {tabList
          .filter((item) =>
            item.id === 'files'
              ? isFieldInProject('files', allowedFields)
              : true
          )
          .map((item, i) => (
            <TabItem
              onClick={setCurrentTab}
              $isActive={item.id === currentTab}
              {...item}
              key={i}
            />
          ))}
      </TabsStyled>
      <TabContent>{renderContent()}</TabContent>
    </>
  );
};
